import { createSlice } from '@reduxjs/toolkit';
import {AppThunk} from '../../store/store';
import {ReceiversHistoryInterface} from "../../interfces/ReceiversInterface";
import { requestHistory } from "../../provider/receiversProvider";
import {getStorageItem} from "../../helpers/authHelper";
import {addErrorNotification} from "../Notifications/notificationsSlice";
import {timeStampToDate} from "../../helpers/format";
import {DEFAULT_PAGE_SIZE} from '../../helpers/constants';
const yesterdayDate = new Date();
yesterdayDate.setDate(yesterdayDate.getDate() - 1);
const initialState: ReceiversHistoryInterface = {
    data: [],
    dateFrom: timeStampToDate(yesterdayDate.getTime()),
    dateTo: timeStampToDate(new Date().getTime()),
    pageSize: DEFAULT_PAGE_SIZE,
    pageCount: 0,
    total: 0,
    page: 0,
    query: '',
    sortBy: 'createdAt',
    selectedPartnerId: null,
    sortDirection: 'desc',
    loading: false
};

export const receiverHistorySlice = createSlice({
    name: 'receiverHistory',
    initialState,
    reducers: {
        setHistory: (state, action) => {
            state.data = action.payload.data;
            state.pageCount = action.payload.pageCount;
            state.total = action.payload.total;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setSortBy: (state, action) => {
            state.sortBy = action.payload;
        },
        setDateFrom: (state, action) => {
            state.dateFrom = action.payload;
        },
        setDateTo: (state, action) => {
            state.dateTo = action.payload;
        },
        setSortDirection: (state, action) => {
            state.sortDirection = action.payload;
        },
        setRowsPerPage: (state, action) => {
            state.pageSize = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setQuery: (state, action) => {
            state.query = action.payload;
        },
        setSelectedPartnerId: (state, action) => {
            state.selectedPartnerId = action.payload;
        }
    },
});

export const {setHistory, setLoading, setSortBy, setSortDirection, setRowsPerPage, setPage, setQuery, setDateFrom, setDateTo, setSelectedPartnerId } = receiverHistorySlice.actions;

export const getHistory = (partnerId:string = ''): AppThunk => async (
    dispatch: (arg0:AppThunk | { payload: any; type: string; }) => void,
    getState
) => {
    dispatch(setLoading(true));
    const {query, sortBy, sortDirection, page, pageSize, dateFrom, dateTo, selectedPartnerId} = getState().receiverHistory;
    const userPartnerId = getStorageItem('partnerId');
    const currentPartnerId = partnerId ? partnerId : selectedPartnerId !== null ? selectedPartnerId : userPartnerId;
    try {
        if (!userPartnerId) return;

        const response = await requestHistory({partnerId: currentPartnerId, dateFrom, dateTo,  page, pageSize, sortBy, sortDirection, query});
        for (const item of response.data.data) {
            item.receiverId = `${item.receiverId}${item.serialNumber ? ' / ' + item.serialNumber : ''}`;
        }
        // @ts-ignore
        dispatch(setHistory(response.data));

    } catch (e) {
        dispatch(addErrorNotification(e));
    }
    dispatch(setLoading(false));
};

export const changeSorting = (property: string): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void,
    getState
) => {

    const {sortDirection} = getState().receiverHistory;
    if (sortDirection === 'asc') {
        dispatch(setSortDirection('desc'))
    } else {
        dispatch(setSortDirection('asc'));
    }
    dispatch(setLoading(true));
    dispatch(getHistory());
}

export const handleQuery = (query: string): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void
) => {
    dispatch(setLoading(true));
    dispatch(setQuery(query));
    dispatch(getHistory());
}
export const changeRowsPerPage = (rowsPerPage: number): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void
) => {
    dispatch(setLoading(true));
    dispatch(setRowsPerPage(rowsPerPage));
    dispatch(getHistory());
}

export const changePage = (pageNumber: number): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void
) => {
    dispatch(setLoading(true));
    dispatch(setPage(pageNumber));
    dispatch(getHistory());
}

export const changeDateFrom = (date: string) =>async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void
) => {
    dispatch(setDateFrom(date));
}
export const changeDateTo = (date: string) =>async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void
) => {
    dispatch(setDateTo(date));
}
export default receiverHistorySlice.reducer;
