import axios from 'axios';

let baseUrl = 'https://prod.api.whocalls.info';
let apiKey = 'tsxHD3PAo67hfvaVBo8NS9CofMXecd6oaKKwrR4m';

// if (process.env.PUBLIC_STAGE === 'local') {
//     baseUrl = 'http://localhost:8080';
//     apiKey = 'localhostapikey';
// }
export default axios.create({
    baseURL: baseUrl,
    headers: {
        "Content-type": "application/json",
        'X-Api-Key': apiKey,
    },
});
