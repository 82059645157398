import http from './axios';
import {getStorageItem} from "../helpers/authHelper";
import {PartnerInterface, PartnersRequestInterface} from "../interfces/PartnersInterface";

export const requestPartners = ({page, pageSize, sortDirection, sortBy, query}: PartnersRequestInterface) => {
    const token = getStorageItem('token');
    const queryParam = query ? `&query=${query}` : '';
    const sortByParam = sortBy === 'since' ? `&sortBy=createdAt` : `&sortBy=${sortBy}`;
    return http.get(`/private/partners?pageSize=${pageSize}&page=${page}${sortByParam}&sortDirection=${sortDirection}${queryParam}`,{
            headers: {
                'Authorization': token
            }
        }
    );
};

export const requestAppConfigUpdate = (partnerId: string) => {
    const token = getStorageItem('token');
    return http.get(`/private/config/${partnerId}/request-app-config-update`,{
            headers: {
                'Authorization': token
            }
        }
    );
}

export const requestAddPartner = (formData: PartnerInterface) => {
    const token = getStorageItem('token');
    return http.post(`/private/partners/`, formData, {
        headers: {
            'Authorization': token
        }
    })
}

export const requestUpdatePartner = (formData: PartnerInterface) => {
    const token = getStorageItem('token');
    const partnerId = encodeURI(formData.partnerId);
    return http.put(`/private/partners/${partnerId}`, {...formData},{
            headers: {
                'Authorization': token
            }
        }
    );
}

export const requestDeletePartner = (partnerId: string) => {
    const token = getStorageItem('token');
    partnerId = encodeURI(partnerId);
    return http.delete(`/private/partners/${partnerId}`, {
            headers: {
                'Authorization': token
            }
        }
    );
}
