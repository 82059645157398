import {AppThunk} from '../../store/store';
import {getStorageItem} from '../../helpers/authHelper';
import {addNotification} from '../Notifications/notificationsSlice';
import {createSlice} from '@reduxjs/toolkit';
import {generateInvoicesRequest} from '../../provider/invoiceProvider';

const initialState = {
    loading: false,
    error: false,
};

export const generateInvoices = (formData: any): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void,
) => {
    dispatch(setLoading(true));
    try {
        const partnerId = getStorageItem('partnerId');
        if (!partnerId && partnerId !== 'admin') return false;

        const response = await generateInvoicesRequest(formData);
        console.log(response);
    } catch (e) {
        const errorText = Object.keys(e.response.data).length > 0 ? e.response.data.errors[0].message : 'something went wrong';
        dispatch(addNotification('error', errorText));
    } finally {
        dispatch(setLoading(false));
    }
};

export const invoicesSlice = createSlice({
    name: 'invoices',
    initialState,
    reducers: {
        setLoading: (state: typeof initialState, action) => {
            state.loading = action.payload;
        }
    }
});

export const {
    setLoading,
} = invoicesSlice.actions;

export default invoicesSlice.reducer;