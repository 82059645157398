import {createSlice} from '@reduxjs/toolkit';
import {AppThunk} from '../../store/store';
import {getStorageItem} from "../../helpers/authHelper";
import {requestDashboard} from "../../provider/dashboardProvider";
import {DashboardRequestInterface, DashboardResponseInterface} from "../../interfces/DashboardInterface";
import {addErrorNotification} from "../Notifications/notificationsSlice";

const initialState: { data: DashboardResponseInterface | null, loading: boolean } = {
    data: null,
    loading: false
};

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setDashboard: (state, action) => {
            state.data = action.payload.data;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    },
});

export const {setDashboard, setLoading} = dashboardSlice.actions;

export const getDashboard = ({partnerId, month, year}: DashboardRequestInterface): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void,
) => {
    dispatch(setLoading(true));
    try {
        const currentPartnerId = getStorageItem('partnerId');
        if (!currentPartnerId) return false;
        const response = await requestDashboard({partnerId, month, year});
        dispatch(setDashboard(response.data));
    } catch (e) {
        dispatch(addErrorNotification(e));
    }
    dispatch(setLoading(false));
};

export default dashboardSlice.reducer;
