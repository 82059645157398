import {getStorageItem} from '../helpers/authHelper';
import http from './axios';


export const generateInvoicesRequest = (formData: any) => {
    const token = getStorageItem('token');
    let url = `/private/admin/generate-invoices?dryRun=${formData.dryRun}`;
    if (formData.acIds && formData.acIds.length > 0) {
        url = url + `&acIds=${formData.acIds}`;
    }
    if (formData.acIdStart && formData.acIdEnd) {
        url = url + `&acIdStart=${formData.acIdStart}&acIdEnd=${formData.acIdEnd}`;
    }

    return http.get(url, {
            headers: {
                'Authorization': token
            }
        }
    );
};