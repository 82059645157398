import {getStorageItem} from '../../helpers/authHelper';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {PartnersSelector} from '../../store/selectors/partnersSelectors';
import {SelectedPartnerIdSelector} from '../../store/selectors/receiversSelector';
import {handlerSelectedPartnerId} from '../Receivers/receiversSlice';
import {FormControl, InputLabel, MenuItem, Select} from '@material-ui/core';
import {PartnerInterface} from '../../interfces/PartnersInterface';
import React from 'react';

export const ReceiversFilter = () => {
    const partnerId = getStorageItem('partnerId');
    const dispatch = useAppDispatch();
    const partners = useAppSelector(PartnersSelector);
    const selectedPartnerId = useAppSelector(SelectedPartnerIdSelector);

    const handleSetPartner = (event: any) => {
        dispatch(handlerSelectedPartnerId(event.target.value));
    };

    return <>
        {partnerId === 'admin' ?
            <FormControl variant="filled" style={{width: 100, background: 'fff', margin: 5}}>
                <InputLabel id="selectPartner">Partner</InputLabel>
                <Select
                    onChange={handleSetPartner}
                    labelId="selectPartner"
                    id="selectPartner"
                    value={selectedPartnerId ?? '*'}
                >
                    <MenuItem value={'*'}>*</MenuItem>
                    {partners.map((partner: PartnerInterface, index) => (
                        <MenuItem key={`${index}-partner`}
                                  value={partner.partnerId}>{partner.name}</MenuItem>))}
                </Select>
            </FormControl> : <></>}
    </>;
};