import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '../components/Menu/Menu';
import {useHistory} from 'react-router-dom';
import AppToolbar from '../components/AppToolbar';
import SubMenu from '../components/Menu/SubMenu';
import {useAppDispatch} from '../hooks';
import {getStorageItem} from '../helpers/authHelper';
import {getPartners} from '../components/Partners/partnersSlice';
import Notifications from '../components/Notifications';
import {PageManager} from '../constants/page';
import {Role} from '../constants/roles';

const ADMIN_ROLE = Role.ADMIN.toString();
const DRAWER_WIDTH = 160;

const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
        },
        appBar: {
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
            marginLeft: DRAWER_WIDTH,
            background: 'lightgray'
        },
        content: {
            marginTop: '60px',
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
        },
    }));

const MainLayout = ({children}: {children: React.ReactElement}) => {
        const classes = useStyles();
        const history = useHistory();
        const dispatch = useAppDispatch();
        const partnerId = getStorageItem('partnerId');
        const [showSubMenu, setShowSubMenu] = useState(true);
        const handleOpenSubMenu = () => {
            setShowSubMenu(true);
        }

        const collapseMenu = () => {
            setShowSubMenu(!showSubMenu);
        }

        useEffect(() => {
            if (partnerId === ADMIN_ROLE) {
                dispatch(getPartners());
            }
        }, [partnerId, dispatch]);

        const page = PageManager.getPage(history.location.pathname);
        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <AppToolbar page={page}/>
                    </Toolbar>
                </AppBar>
                <Menu openSubMenu={handleOpenSubMenu} />
                <SubMenu collapseMenu={collapseMenu} showMenu={showSubMenu}/>
                <main className={classes.content} style={{marginLeft: showSubMenu ? '130px' : '30px'}}>
                    {children}
                    <Notifications />
                </main>
            </div>
        );
    };

export default MainLayout;
