import {RootState} from "../store";

export const ReceiversSelector = (state: RootState) => state.receivers.data;
export const EditReceiverSelector = (state: RootState) => state.receivers.receiverEdited;
export const isActiveSelector = (state: RootState) => state.receivers.isActive;
export const PageSelector = (state: RootState) => state.receivers.page;
export const SortBySelector = (state: RootState) => state.receivers.sortBy;
export const SortDirectionSelector = (state: RootState) => state.receivers.sortDirection;
export const PageSizeSelector = (state: RootState) => state.receivers.pageSize;
export const TotalSizeSelector = (status: RootState) => status.receivers.total;
export const TotalPagesSelector = (state: RootState) => state.receivers.pageCount;
export const LoadingSelector = (state: RootState) => state.receivers.loading;
export const SelectedPartnerIdSelector = (state: RootState) => state.receivers.selectedPartnerId;
