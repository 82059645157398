import React, {useEffect, useState} from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import {getReceivers, upsertReceiver} from '../Receivers/receiversSlice';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {PartnersSelector} from '../../store/selectors/partnersSelectors';
import {makeStyles} from '@material-ui/core/styles';
import {ReceiverInterface} from '../../interfces/ReceiversInterface';
import {PartnerInterface} from '../../interfces/PartnersInterface';
import {EditReceiverSelector, LoadingSelector} from '../../store/selectors/receiversSelector';
import {getBooleanStorageItem, getStorageItem} from '../../helpers/authHelper';
import {countries, CountryItem} from './countries';
import {Role} from '../../constants/roles';
import {ReceiverType} from '../../interfces/api/receiver-type';

const ADMIN_ROLE = Role.ADMIN.toString();

const useStyles = makeStyles({
    newPosOfDialog: {
        position: 'absolute',
        width: '100%',
        minWidth: '780px',
        top: '50%',
        left: '50%',
        transform: 'translate(-55%, -50%)',
    },
    clarificationLabel: {
        marginBottom: '0.5rem',
        marginTop: '0.5rem',
    },
});


const maxSMSPerMessageCount = 3;
const getSMSCount = (message: string) => {
    // if up to 160 characters, it's 1 SMS
    // if up to 306 characters, it's 2 SMS
    // if up to 459 characters, it's 3 SMS

    if (message.length <= 160) {
        return 1;
    } else if (message.length <= 306) {
        return 2;
    } else if (message.length <= 459) {
        return 3;
    }
    return 4;
};
const allowedSMSCharacters = /[^\x00-\x7F]/g;

const ReceiverEditFormDialog = ({
                                    handleOpen,
                                    receiver,
                                    open,
                                }: { handleOpen: (value: boolean) => void, receiver?: ReceiverInterface | null, open: boolean }) => {

    const dispatch = useAppDispatch();
    const classes = useStyles();
    const partners = useAppSelector(PartnersSelector);
    const receiverEdited = useAppSelector(EditReceiverSelector);
    const [receiverId, setReceiverId] = useState('');
    const [partnerId, setPartnerId] = useState('');
    const [type, setType] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    const [businessId, setBusinessId] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [status, setStatus] = useState(false);
    const [comment, setComment] = useState('');
    const [localMessage, setLocalMessage] = useState('');
    const [internationalMessage, setInternationalMessage] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [hasFeatureSMSAutoReply, setHasFeatureSMSAutoReply] = useState(false);
    const [maxSMSCount, setMaxSMSCount] = useState(100);
    const [receiverTrialDays, setReceiverTrialDays] = useState<number | null | undefined>(null);
    const [authPhoneNumber, setAuthPhoneNumber] = useState('');
    const [authNumberError, setAuthNumberError] = useState('');
    const [sipUsername, setSipUsername] = useState('');
    const [sipPassword, setSipPassword] = useState('');
    const [sipDomain, setSipDomain] = useState('');
    const [error, setError] = useState(false);
    const loading = useAppSelector(LoadingSelector);
    const userPartnerId = getStorageItem('partnerId');
    const hasPartnerFeatureSMSAutoReply = getBooleanStorageItem('hasPartnerFeatureSMSAutoReply');

    const originalPartnerId = receiver?.partnerId ?? userPartnerId;

    useEffect(() => {
        if (receiver) {
            const {
                receiverId,
                partnerId,
                type,
                businessId,
                businessName,
                comment,
                serialNumber,
                hasFeatureSMSAutoReply,
                maxSMSCount,
                localMessage,
                internationalMessage,
                countryCode,
                receiverTrialDays,
                sipUsername,
                sipPassword,
                sipDomain,
                authPhoneNumber,
            } = receiver;
            if (receiver.isActive) {
                setStatus(true);
            } else {
                setStatus(false);
            }
            if (type === 'box') {
                if (serialNumber) {
                    setSerialNumber(serialNumber);
                }
            }
            if (hasFeatureSMSAutoReply) {
                setHasFeatureSMSAutoReply(true);
            }
            if (maxSMSCount) {
                setMaxSMSCount(maxSMSCount);
            }
            setLocalMessage(localMessage ?? '');
            setInternationalMessage(internationalMessage ?? '');
            if (countryCode) {
                setCountryCode(countryCode);
            }
            setReceiverId(receiverId);
            setPartnerId(partnerId);
            setType(type);
            setBusinessId(businessId);
            setBusinessName(businessName);
            setComment(comment);
            setReceiverTrialDays(receiverTrialDays);
            setSipUsername(sipUsername ?? '');
            setSipPassword(sipPassword ?? '');
            setSipDomain(sipDomain ?? '');
            setAuthPhoneNumber(authPhoneNumber ?? '');
        }
    }, [receiver]);
    const clearForm = () => {
        setPartnerId('');
        setReceiverId('');
        setType('');
        setBusinessId('');
        setBusinessName('');
        setStatus(false);
        setMaxSMSCount(100);
        setCountryCode('');
        setLocalMessage('');
        setInternationalMessage('');
        setHasFeatureSMSAutoReply(false);
        setSerialNumber('');
        setComment('');
        setReceiverTrialDays(null);
        setSipUsername('');
        setSipPassword('');
        setSipDomain('');
    };
    const handleClose = () => {
        clearForm();
        handleOpen(false);
    };
    useEffect(() => {
        handleClose();
    }, [receiverEdited]);

    const handleConfirm = () => {
        const formData: ReceiverInterface = {
            partnerId,
            receiverId,
            type,
            businessId,
            businessName,
            isActive: status,
            comment,
            serialNumber,
            hasFeatureSMSAutoReply,
            maxSMSCount,
            localMessage,
            internationalMessage,
            countryCode,
            receiverTrialDays,
            sipUsername,
            sipPassword,
            sipDomain,
            authPhoneNumber,
        };
        const validateData = {
            partnerId,
            businessId,
            businessName,
            countryCode,
            type,
        };
        if (!(partnerId || partnerId !== '') && userPartnerId !== 'admin') {
            setPartnerId(userPartnerId);
            validateData.partnerId = userPartnerId;
        }

        // @ts-ignore
        if (!Object.keys(validateData).every((el) => validateData[el].length > 0)) {
            setError(true);
            console.error(`${JSON.stringify(validateData)}`);
            return false;
        }
        if (type === 'box' && !serialNumber) {
            setError(true);
            return false;
        }

        if (hasFeatureSMSAutoReply && !localMessage) {
            setError(true);
            return false;
        }
        const localSMSCount = getSMSCount(localMessage);
        if (localSMSCount > 1) {
            setError(true);
            if (localSMSCount > maxSMSPerMessageCount) {
                return false;
            }
        }
        const internationalSMSCount = getSMSCount(internationalMessage);
        if (internationalSMSCount > 1) {
            setError(true);
            if (internationalSMSCount > maxSMSPerMessageCount) {
                return false;
            }
        }

        if (type === ReceiverType.voipstudio) {
            const isSipValid = sipUsername.length > 0 && sipPassword.length > 0 && sipDomain.length > 0;
            if (!isSipValid) {
                setError(true);
                return false;
            }

            const isAuthNumberValid = authPhoneNumber.length >= 12 && authPhoneNumber.startsWith('00');
            if (!isAuthNumberValid) {
                setAuthNumberError('Invalid phone number, must start with 00 and be at least 12 digits long');
                setError(true);
                return false;
            }
        }

        dispatch(upsertReceiver({
            formData,
            previousPartnerId: originalPartnerId,
        }));
        setTimeout(() => {
            dispatch(getReceivers());
        }, 2000);
    };

    const updateCountryCode = (typeValue: string) => {
        const e164CallerId = typeValue.replace('00', '+');
        const countryFromNumber = countries.find((el: CountryItem) => e164CallerId.startsWith(el.code));
        setCountryCode(countryFromNumber?.code ?? '');
    };

    const handleChangeSelect = (type: string, event: React.ChangeEvent<{ name?: string; value: any }>) => {
        switch (type) {
            case 'hasFeatureSMSAutoReply':
                setHasFeatureSMSAutoReply(event.target.value === 'on');
                break;
            case 'type':
                // @ts-ignore
                const typeValue = event.target.value;
                if (typeValue === 'mobile' && receiverId) {
                    updateCountryCode(typeValue);
                }
                setType(typeValue);
                break;
            case 'status':
                setStatus(event.target.value === 'true');
                break;
            case 'partnerId':
                // @ts-ignore
                setPartnerId(event.target.value);
                break;
            case 'countryCode':
                setCountryCode(event.target.value);
                break;
            default:
                break;
        }
    };
    const handleChange = (actionType: string, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        switch (actionType) {
            case 'receiverTrialDays':
                const value = event.target.value;
                if (value.length === 0) {
                    setReceiverTrialDays(null);
                    break;
                }
                const trialDays = parseInt(value);
                if (isNaN(trialDays)) {
                    setReceiverTrialDays(null);
                    break;
                }
                setReceiverTrialDays(Number(event.target.value));
                break;
            case 'maxSMSCount': {
                const count = Number(event.target.value);
                if (!isNaN(count)) {
                    setMaxSMSCount(count);
                }
                break;
            }
            case 'localMessage': {
                const val = event.target.value.replace(allowedSMSCharacters, '');
                setLocalMessage(val);
                break;
            }
            case 'internationalMessage': {
                const val = event.target.value.replace(allowedSMSCharacters, '');
                setInternationalMessage(val);
                break;
            }
            case 'receiverId': {
                const content = event.target.value;
                if (type === ReceiverType.box) {
                    setReceiverId(event.target.value);
                    // the regex verifies if the content is only numbers
                } else if ((/^\d+$/gmi.test(content)) || content.length === 0 || content.length < receiverId.length) {
                    setReceiverId(event.target.value);
                    if ([ReceiverType.mobile, ReceiverType.voipstudio, ReceiverType.threeCX].includes(type as ReceiverType)) {
                        updateCountryCode(content);
                    }
                }
                break;
            }

            case 'businessId':
                setBusinessId(event.target.value);
                break;
            case 'businessName':
                setBusinessName(event.target.value);
                break;
            case 'serialNumber':
                setSerialNumber(event.target.value);
                break;
            case 'comment':
                setComment(event.target.value);
                break;
            case 'sipUsername':
                setSipUsername(event.target.value);
                break;
            case 'sipPassword':
                setSipPassword(event.target.value);
                break;
            case 'sipDomain':
                setSipDomain(event.target.value);
                break;
            case 'authPhoneNumber':
                setAuthPhoneNumber(event.target.value);
                break;
            default:
                break;
        }
    };
    const localSMSCount = getSMSCount(localMessage);
    const internationalSMSCount = getSMSCount(internationalMessage);

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" classes={{
                paper: classes.newPosOfDialog,
            }}>
                <DialogTitle id="form-dialog-title">Manage Receiver</DialogTitle>
                <DialogContent>
                    <Grid container spacing={4}>
                        <Grid item lg={type === 'box' ? 6 : 12} xs={12}>
                            <FormControl fullWidth required error={error && type.length === 0}
                                         disabled={userPartnerId !== ADMIN_ROLE && !!receiver}>
                                <InputLabel id="selectType">Type</InputLabel>
                                <Select
                                    onChange={(event) => handleChangeSelect('type', event)}
                                    labelId="selectType"
                                    id="selectType"
                                    value={type}
                                >
                                    {(userPartnerId === ADMIN_ROLE || type === ReceiverType.box) &&
                                        <MenuItem value={ReceiverType.box}>Box</MenuItem>}
                                    {(userPartnerId === ADMIN_ROLE) &&
                                        <MenuItem value={ReceiverType.voipstudio}>wikoti | assistant</MenuItem>}
                                    <MenuItem value={ReceiverType.mobile}>Mobile</MenuItem>
                                    <MenuItem value={ReceiverType.threeCX}>3CX</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {type === 'box' && <Grid item lg={6} xs={12}>
                            <FormControl fullWidth required error={error && receiverId.length === 0}
                                         disabled={userPartnerId !== ADMIN_ROLE}>
                                <InputLabel htmlFor="serialNumber">Serial Number</InputLabel>
                                <Input id="serialNumber" value={serialNumber}
                                       onChange={(event) => handleChange('serialNumber', event)}/>
                            </FormControl>
                        </Grid>}
                        {userPartnerId === ADMIN_ROLE && partners.length > 0 && <Grid item lg={12} xs={12}>
                            <FormControl fullWidth required error={error && partnerId.length === 0}>
                                <InputLabel id="selectPartner">Partner</InputLabel>
                                <Select
                                    onChange={(event) => handleChangeSelect('partnerId', event)}
                                    labelId="selectPartner"
                                    id="selectPartner"
                                    value={partnerId}
                                >
                                    <MenuItem value={''}/>
                                    {partners.map((partner: PartnerInterface) => (
                                        <MenuItem key={partner.partnerId}
                                                  value={partner.partnerId}>{partner.name}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </Grid>}
                        <Grid item lg={6} xs={12}>
                            <FormControl fullWidth disabled={userPartnerId !== ADMIN_ROLE && !!receiver}>
                                <InputLabel htmlFor="receiverId">ReceiverID</InputLabel>
                                <Input id="receiverId" value={receiverId}
                                       onChange={(event) => handleChange('receiverId', event)}/>
                            </FormControl>
                        </Grid>

                        <Grid item lg={6} xs={12}>
                            <FormControl fullWidth required error={error && businessId.length === 0}>
                                <InputLabel htmlFor="businessId">BusinessID</InputLabel>
                                <Input id="businessId" onChange={(event) => handleChange('businessId', event)}
                                       value={businessId}/>
                            </FormControl>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <FormControl fullWidth required error={error && businessName.length === 0}>
                                <InputLabel htmlFor="buisnessName">Business Name</InputLabel>
                                <Input id="buisnessName" onChange={(event) => handleChange('businessName', event)}
                                       value={businessName}/>
                            </FormControl>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <FormControl fullWidth required error={error && countryCode.length === 0}
                                         disabled={type === 'mobile'}>
                                <InputLabel id="selectCountryCode">Country Code</InputLabel>
                                <Select
                                    onChange={(event) => handleChangeSelect('countryCode', event)}
                                    labelId="countryCode"
                                    id="countryCode"
                                    value={countryCode}
                                >
                                    <MenuItem value={''}/>
                                    {countries.map((item: CountryItem) => (
                                        <MenuItem key={item.country}
                                                  value={item.code}>{item.country} ({item.code})</MenuItem>))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {type === ReceiverType.voipstudio && (
                            <>
                                <Grid item lg={4} xs={12}>
                                    <FormControl fullWidth required error={sipUsername.length === 0}>
                                        <InputLabel error={sipUsername.length === 0} htmlFor="sipUsername">SIP
                                            Username</InputLabel>
                                        <Input id="sipUsername"
                                               onChange={(event) => handleChange('sipUsername', event)}
                                               value={sipUsername}/>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={4} xs={12}>
                                    <FormControl fullWidth required error={sipPassword.length === 0}>
                                        <InputLabel error={sipPassword.length === 0} htmlFor="sipPassword">SIP
                                            Password</InputLabel>
                                        <Input id="sipPassword"
                                               onChange={(event) => handleChange('sipPassword', event)}
                                               value={sipPassword}/>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={4} xs={12}>
                                    <FormControl fullWidth required error={sipDomain.length === 0}>
                                        <InputLabel error={sipDomain.length === 0} htmlFor="sipDomain">SIP
                                            Domain</InputLabel>
                                        <Input id="sipDomain" onChange={(event) => handleChange('sipDomain', event)}
                                               value={sipDomain}/>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                    <FormControl fullWidth required error={authPhoneNumber.length === 0}>
                                        <InputLabel
                                            htmlFor="authPhoneNumber">{error ? `Authentication Phone Number (${authNumberError})` : 'Authentication Phone Number'}</InputLabel>
                                        <Input id="sipDomain"
                                               onChange={(event) => handleChange('authPhoneNumber', event)}
                                               value={authPhoneNumber}/>
                                    </FormControl>
                                </Grid>
                            </>
                        )}

                        {hasPartnerFeatureSMSAutoReply && <>
                            <Grid item lg={12} xs={12}>
                                <InputLabel htmlFor="localMessage"
                                            className={classes.clarificationLabel}
                                            error={error && localMessage.length === 0 && hasFeatureSMSAutoReply}>Enter
                                    the
                                    main SMS message, sent when the caller and the callee are in the same country,
                                    usually
                                    local language.</InputLabel>
                                <FormControl fullWidth>
                                    <TextField
                                        fullWidth
                                        id="localMessage"
                                        multiline
                                        minRows={4}
                                        value={localMessage}
                                        onChange={(event) => handleChange('localMessage', event)}
                                        variant="outlined"
                                    />
                                </FormControl>
                                <InputLabel error={localSMSCount > 1} htmlFor="localMessage"
                                            className={classes.clarificationLabel}>{localMessage.length} characters.
                                    Will
                                    cost {localSMSCount} SMS/message. {localSMSCount > 1 ? `Please make sure the customer understands and accepts this or reduce the length to maximum 160 characters.` : ''}
                                </InputLabel>
                            </Grid>
                            <Grid item lg={12} xs={12}>
                                <InputLabel htmlFor="internationalMessage" className={classes.clarificationLabel}>
                                    Enter the alternate SMS message, sent when the caller and the callee are in
                                    different
                                    countries, usually in English.</InputLabel>
                                <FormControl fullWidth>
                                    <TextField
                                        fullWidth
                                        id="internationalMessage"
                                        multiline
                                        minRows={4}
                                        value={internationalMessage}
                                        onChange={(event) => handleChange('internationalMessage', event)}
                                        variant="outlined"
                                    />
                                </FormControl>
                                <InputLabel error={internationalSMSCount > 1}
                                            htmlFor="internationalMessage"
                                            className={classes.clarificationLabel}>{internationalMessage.length} characters.
                                    Will
                                    cost {internationalSMSCount} SMS/message. {internationalSMSCount > 1 ? `Please make sure the customer understands and accepts this or reduce the length to maximum 160 characters.` : ''}
                                </InputLabel>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <FormControl fullWidth required>
                                    <InputLabel id="hasFeatureSMSAutoReply">SMS Auto Reply</InputLabel>
                                    <Select
                                        onChange={(event) => handleChangeSelect('hasFeatureSMSAutoReply', event)}
                                        labelId="hasFeatureSMSAutoReply"
                                        id="hasFeatureSMSAutoReply"
                                        value={hasFeatureSMSAutoReply ? 'on' : 'off'}
                                    >
                                        <MenuItem key={'on'} value={'on'}>{'On'}</MenuItem>
                                        <MenuItem key={'off'} value={'off'}>{'Off'}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <FormControl fullWidth required
                                             error={error && hasFeatureSMSAutoReply && maxSMSCount === 0}>
                                    <InputLabel htmlFor="maxSMSCount">Maximum number of monthly sent SMS.</InputLabel>
                                    <Input id="maxSMSCount" onChange={(event) => handleChange('maxSMSCount', event)}
                                           value={maxSMSCount}/>
                                </FormControl>
                            </Grid>
                        </>}
                        <Grid item lg={12} xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    fullWidth
                                    id="comment"
                                    label="Comment"
                                    multiline
                                    minRows={4}
                                    value={comment}
                                    onChange={(event) => handleChange('comment', event)}
                                    variant="outlined"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="status">Status</InputLabel>
                                <Select
                                    labelId="status"
                                    id="status"
                                    value={status ? 'true' : 'false'}
                                    onChange={(event) => handleChangeSelect('status', event)}
                                >
                                    <MenuItem value={'false'}>Off</MenuItem>
                                    <MenuItem value={'true'}>On</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <FormControl fullWidth disabled={userPartnerId !== ADMIN_ROLE}>
                                <InputLabel htmlFor="receiverTrialDays">Receiver Trial Days</InputLabel>
                                <Input id="receiverTrialDays"
                                       value={receiverTrialDays} type={'number'}
                                       onChange={(event) => handleChange('receiverTrialDays', event)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {error && <span style={{color: 'red', fontWeight: 'bold'}}>All fields are required*</span>}
                    <Button disabled={loading} onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleConfirm} color="primary">
                        {loading ? <CircularProgress size={25} style={{color: '#fff'}}/> : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ReceiverEditFormDialog;
