const storage = {
    get(name: string): string {
        return localStorage.getItem(name) as string;
    },
    set(name: string, value: string): void {
        localStorage.setItem(name, value);
    },
    unset(name: string) {
        localStorage.removeItem(name);
    }
}

export default storage;
