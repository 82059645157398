import storage from "./storage";

const parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};
export const saveToken = (authenticationToken: string, authenticationTokenType: string ): void => {
    storage.set('token', `${authenticationTokenType} ${authenticationToken}`);
    const jsonToken = parseJwt(authenticationToken);
    const tokenData = jsonToken.data;
    // uId: existingUser.id,
    // e: existingUser.email,
    // a: existingUser.isAdmin,
    // p: existingUser.partnerId
    // sms: existingUser.sms
    storage.set('email', tokenData.e);
    storage.set('isAdmin', tokenData.a);
    storage.set('partnerId', tokenData.p);
    storage.set('userId', tokenData.uId);
    storage.set('hasPartnerFeatureSMSAutoReply', tokenData.sms);
}

export const getStorageItem = (name: string): string => {
    return storage.get(name);
}

export const getBooleanStorageItem = (name: string): boolean => {
    const value = storage.get(name);
    return value === 'true';
}
export const removeStorageItem = (name: string): void => {
    storage.unset(name);
}

export const isLoggedIn = (): boolean => {
    return !!getStorageItem('token');
}
