import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {ColumnsInterface, TABLE_HEADERS} from '../../constants/tableHeaders';
import {IconButton} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import NotificationIcon from '@material-ui/icons/Notifications';
import {formatDateForDisplay} from '../../helpers/format';
import useWindowDimensions from '../../hooks';
import {getBooleanStorageItem} from '../../helpers/authHelper';
import {Page} from '../../constants/page';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    th: {
        padding: 0,
        paddingStart: '0.5em',
        maxWidth: '8rem',
    },
    td: {
        padding: 0,
        paddingStart: '0.5rem',
    }
});

interface PartnersTableInterface {
    pageName: Page,
    loading: boolean,
    data: any[],
    page: number,
    pageSize: number;
    setPage: (page: number) => void;
    setRowsPerPage: (rowsPerPage: number) => void;
    sortBy?: string,
    sortDirection: 'asc' | 'desc',
    onRequestSort: (property: string) => void,
    editItem?: ({itemId, type}: { itemId: string, type: string }) => void,
    deleteItem?: ({itemId, type}: { itemId: string, type: string }) => void,
    requestConfigUpdate?: ({itemId, type}: { itemId: string, type: string }) => void,
    totalCount: number
}

const humanReadableBoolean = (value: boolean) => (value ? 'on' : 'off');


const getValue = (column: ColumnsInterface, row: any) => {
    if (column.name === 'isActive'
        || column.name === 'hasFeatureSMSAutoReply') {

        return humanReadableBoolean(row[column.name]);
    }
    return row[column.name];
};

export default function PartnersTable(props: PartnersTableInterface) {
    const {
        pageName,
        data,
        loading,
        page,
        pageSize,
        setPage,
        setRowsPerPage,
        sortBy,
        sortDirection,
        onRequestSort,
        totalCount,
        deleteItem,
        editItem,
        requestConfigUpdate
    } = props;

    const classes = useStyles();
    const {height} = useWindowDimensions();

    const hasPartnerFeatureSMSAutoReply = getBooleanStorageItem('hasPartnerFeatureSMSAutoReply');
    const columns: ColumnsInterface[] = TABLE_HEADERS[pageName].filter(column => column.name !== 'hasFeatureSMSAutoReply' || hasPartnerFeatureSMSAutoReply);

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: { target: { value: React.ReactText } }) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const createSortHandler = (property: string) => () => {
        onRequestSort(property);
    };
    const itemName = (row: any) => {
        if (pageName === Page.RECEIVERS_MANAGE) {
            return {itemId: row.receiverId, type: row.type};
        }
        if (pageName === Page.PARTNERS_MANAGE) {
            return {itemId: row.partnerId, type: ''};
        }
        return {itemId: '', type: ''};
    };
    const handleDelete = (row: any): void => {
        const {itemId, type} = itemName(row);
        deleteItem && deleteItem({itemId, type});
    };
    const handleEdit = (row: any) => {
        editItem && editItem(itemName(row));
    };

    const handleRequestConfigUpdate = (row: any) => {
        const {itemId, type} = itemName(row);
        requestConfigUpdate && requestConfigUpdate({itemId, type});
    };

// @ts-ignore
    return (
        <Paper className={classes.root}>
            <TableContainer style={{height: height - 160, minHeight: '500px'}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => {
                                if (column.sortable) {
                                    return (
                                        <TableCell
                                            width={column.width}
                                            className={classes.th}
                                            key={column.name}
                                            sortDirection={sortBy === column.name ? sortDirection : false}>
                                            <TableSortLabel
                                                active={sortBy === column.name}
                                                direction={sortBy === column.name ? sortDirection : 'desc'}
                                                onClick={createSortHandler(column.name)}>
                                                {column.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    );
                                } else {
                                    return (
                                        <TableCell key={column.name} width={column.width}>
                                            {column.label}
                                        </TableCell>
                                    );
                                }
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? <></> : !data || data.length === 0 ?
                            <TableRow>
                                <TableCell colSpan={columns.length} align="center" className={classes.td}>
                                    <Typography variant="h5" style={{marginTop: 10}}>
                                        No items to display
                                    </Typography>
                                </TableCell>
                            </TableRow> : data.map((row, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        {columns.map((column, index) => {
                                            // @ts-ignore
                                            const value = getValue(column, row);
                                            if (column.name === 'actions' && columns.map(el => el.name).includes('actions')) {
                                                return <TableCell key={index} className={classes.td}>
                                                    <IconButton onClick={() => handleDelete(row)}
                                                                aria-label="delete" style={{color: 'red'}}>
                                                        <DeleteIcon fontSize="small"/>
                                                    </IconButton>
                                                    <IconButton onClick={() => handleEdit(row)}
                                                                aria-label="edit"
                                                                color="primary">
                                                        <CreateIcon fontSize="small"/>
                                                    </IconButton>
                                                    <IconButton onClick={() => handleRequestConfigUpdate(row)}
                                                                aria-label="request-config-update"
                                                                color="primary">
                                                        <NotificationIcon fontSize="small"/>
                                                    </IconButton>
                                                </TableCell>;
                                            }

                                            return (
                                                <TableCell key={column.name} className={classes.td}>
                                                    {column.name === 'since' || column.name === 'createdAt' ? formatDateForDisplay(value) : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[20, 100, 200, 500, 1000, 10000]}
                component="div"
                count={totalCount}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
