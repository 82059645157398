import React, {useEffect, useState} from 'react';
import {
    Button,
    CircularProgress,
    FormControl,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import {upsertReceiver} from './receiversSlice';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {PartnersSelector} from '../../store/selectors/partnersSelectors';
import {PartnerInterface} from '../../interfces/PartnersInterface';
import {useHistory, useLocation} from 'react-router-dom';
import {getStorageItem} from '../../helpers/authHelper';
import {getReceiverById} from '../../provider/receiversProvider';
import Notifications from '../Notifications';
import {addErrorNotification} from '../Notifications/notificationsSlice';
import {notificationSelector} from '../../store/selectors/notificationsSelectors';
import {LoadingSelector} from '../../store/selectors/receiversSelector';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import AppToolbar from '../AppToolbar';
import {getPartners} from '../Partners/partnersSlice';
import {PageManager} from '../../constants/page';
import {Role} from '../../constants/roles';
import {ReceiverInterface} from '../../interfces/ReceiversInterface';

const ADMIN_ROLE = Role.ADMIN.toString();

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        width: `100%`,
        marginLeft: drawerWidth,
        background: 'lightgray',
    },
    content: {
        marginTop: '50px',
        marginLeft: '130px',
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
}));
const ScanReceiverPage = () => {

    const classes = useStyles();
    const history = useHistory();
    const urlSearchParams = new URLSearchParams(useLocation().search);
    const urlReceiverId = urlSearchParams.get('ID');
    const serialNumber = urlSearchParams.get('SN');
    const dispatch = useAppDispatch();
    const notifications = useAppSelector(notificationSelector);
    const loading = useAppSelector(LoadingSelector);
    const partners = useAppSelector(PartnersSelector);
    const [receiverId, setReceiverId] = useState('');
    const [stateSerialNumber, setStateSerialNumber] = useState('');
    const [partnerId, setPartnerId] = useState('');
    const [type, setType] = useState('');
    const [businessId, setBusinessId] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [status, setStatus] = useState(false);
    const [comment, setComment] = useState('');
    const [receiverTrialDays, setReceiverTrialDays] = useState<number | null>(null);
    const [error, setError] = useState(false);
    const userPartnerId = getStorageItem('partnerId');
    const page = PageManager.getPage(history.location.pathname);

    useEffect(() => {
        dispatch(getPartners());
    }, [dispatch]);

    useEffect(() => {
        async function fetchReceiver() {
            try {
                // @ts-ignore
                let response = await getReceiverById(urlReceiverId, userPartnerId);
                // @ts-ignore
                const {
                    partnerId,
                    type,
                    businessId,
                    businessName,
                    comment,
                    serialNumber,
                    isActive,
                    receiverTrialDays,
                } = response.data.data;
                if (isActive) {
                    setStatus(true);
                } else {
                    setStatus(false);
                }
                setPartnerId(partnerId);
                setStateSerialNumber(serialNumber);
                setType(type);
                setBusinessId(businessId);
                setBusinessName(businessName);
                setComment(comment);
                setReceiverTrialDays(receiverTrialDays);
            } catch (e) {
                setType('box');
                setPartnerId("");
                setReceiverId("");
                setStateSerialNumber("");

                dispatch(addErrorNotification({response: {data: {errors: [{message: 'Receiver not found'}]}}}));
            }
        }

        if (!urlReceiverId) {
            dispatch(addErrorNotification({response: {data: {errors: [{message: 'No receiver passed'}]}}}));
        } else {
            setReceiverId(urlReceiverId);
            setStateSerialNumber(serialNumber as string);
            fetchReceiver();
        }
    }, [dispatch, urlReceiverId, userPartnerId, serialNumber]);

    useEffect(() => {
        if (notifications.type === 'success') {
            history.push('/receivers/manage');
        }
    }, [notifications, history]);


    const handleConfirm = () => {
        const formData: ReceiverInterface = {
            partnerId,
            receiverId,
            type,
            businessId,
            businessName,
            isActive: status,
            comment,
            serialNumber: stateSerialNumber,
            receiverTrialDays,
        };
        const validateData = {
            partnerId,
        };
        // @ts-ignore
        if (!Object.keys(validateData).every((el) => validateData[el].length > 0)) {
            setError(true);
            return false;
        }
        dispatch(upsertReceiver({formData, previousPartnerId: partnerId}));
    };
    const handleChangeSelect = (type: string, event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        switch (type) {
            case 'status':
                setStatus(event.target.value === 'true');
                break;
            case 'type':
                setType(event.target.value as string);
                break;
            case 'partnerId':
                // @ts-ignore
                setPartnerId(event.target.value);
                break;
            default:
                break;
        }
    };
    const handleChange = (type: string, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        switch (type) {
            case 'receiverTrialDays':
                const value = event.target.value;
                if (value.length === 0) {
                    setReceiverTrialDays(null);
                    break;
                }
                const trialDays = parseInt(value);
                if (isNaN(trialDays)) {
                    setReceiverTrialDays(null);
                    break;
                }
                setReceiverTrialDays(Number(event.target.value));
                break;
            case 'receiverId':
                setReceiverId(event.target.value);
                break;

            case 'businessId':
                setBusinessId(event.target.value);
                break;
            case 'businessName':
                setBusinessName(event.target.value);
                break;

            case 'comment':
                setComment(event.target.value);
                break;
            case 'stateSerialNumber':
                setStateSerialNumber(event.target.value);
                break;
            default:
                break;
        }
    };
    return (
        <Grid container spacing={4} style={{marginTop: 80, padding: 20}}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <AppToolbar page={page}/>
                </Toolbar>
            </AppBar>
            {type === 'box' && <Grid item lg={6} xs={12} style={{marginTop: 50}}>
                <FormControl fullWidth required error={error && receiverId.length === 0}
                             disabled={userPartnerId !== ADMIN_ROLE}>
                    <InputLabel htmlFor="serialNumber">Serial Number</InputLabel>
                    <Input id="serialNumber" value={stateSerialNumber}
                           onChange={(event) => handleChange('stateSerialNumber', event)}/>
                </FormControl>
            </Grid>}
            {partners.length > 0 && userPartnerId === ADMIN_ROLE ?
                <Grid item lg={6} xs={12} style={{marginTop: 50}}>
                    <FormControl fullWidth required error={error && partnerId.length === 0}>
                        <InputLabel id="selectType">Partner</InputLabel>
                        <Select
                            onChange={(event) => handleChangeSelect('partnerId', event)}
                            labelId="selectPartner"
                            id="selectPartner"
                            value={partnerId}
                        >
                            {partners.map((partner: PartnerInterface) => (
                                <MenuItem key={partner.partnerId}
                                          value={partner.partnerId}>{partner.name}</MenuItem>))}
                        </Select>
                    </FormControl>
                </Grid> :
                <Grid item lg={12} xs={12}>
                    <FormControl fullWidth disabled>
                        <InputLabel htmlFor="partnerId">PartnerID</InputLabel>
                        <Input id="partnerId" value={partnerId}/>
                    </FormControl>
                </Grid>}
            <Grid item lg={6} xs={12}>
                <FormControl fullWidth disabled={userPartnerId !== ADMIN_ROLE}>
                    <InputLabel htmlFor="receiverId">ReceiverID</InputLabel>
                    <Input id="receiverId" value={receiverId}
                           onChange={(event) => handleChange('receiverId', event)}/>
                </FormControl>
            </Grid>
            <Grid item lg={6} xs={12}>
                <FormControl fullWidth required error={error && type.length === 0}
                             disabled={userPartnerId !== ADMIN_ROLE}>
                    <InputLabel id="selectType">Type</InputLabel>
                    <Select
                        onChange={(event) => handleChangeSelect('type', event)}
                        labelId="selectType"
                        id="selectType"
                        value={type}
                    >
                        <MenuItem value={'box'}>Box</MenuItem>
                        <MenuItem value={'mobile'}>Mobile</MenuItem>
                        <MenuItem value={'voipstudio'}>wikoti | assistant</MenuItem>
                    </Select>
                </FormControl>

            </Grid>
            <Grid item lg={6} xs={12}>
                <FormControl fullWidth error={error && businessId.length === 0}>
                    <InputLabel htmlFor="businessId">BusinessID</InputLabel>
                    <Input id="businessId" onChange={(event) => handleChange('businessId', event)}
                           value={businessId}/>
                </FormControl>
            </Grid>
            <Grid item lg={6} xs={12}>
                <FormControl fullWidth error={error && businessName.length === 0}>
                    <InputLabel htmlFor="buisnessName">Business Name</InputLabel>
                    <Input id="buisnessName" onChange={(event) => handleChange('businessName', event)}
                           value={businessName}/>
                </FormControl>
            </Grid>
            <Grid item lg={12} xs={12}>
                <FormControl fullWidth>
                    <TextField
                        fullWidth
                        id="comment"
                        label="Comment"
                        multiline
                        minRows={4}
                        value={comment}
                        onChange={(event) => handleChange('comment', event)}
                        variant="outlined"
                    />
                </FormControl>
            </Grid>
            <Grid item lg={6} xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="status">Status</InputLabel>
                    <Select
                        labelId="status"
                        id="status"
                        value={status ? 'true' : 'false'}
                        onChange={(event) => handleChangeSelect('status', event)}
                    >
                        <MenuItem value={'false'}>Off</MenuItem>
                        <MenuItem value={'true'}>On</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            {userPartnerId === ADMIN_ROLE && <Grid item lg={6} xs={12}>
                <FormControl fullWidth error={error}>
                    <InputLabel htmlFor="receiverTrialDays">Receiver Trial Days</InputLabel>
                    <Input id="receiverTrialDays" value={receiverTrialDays} type={'number'}
                           onChange={(event) => handleChange('receiverTrialDays', event)}/>
                </FormControl>
            </Grid>}

            <Grid item xs={12}>
                <Button disabled={!receiverId && !partnerId && !businessName && !businessId} onClick={handleConfirm}
                        variant="contained" color="primary" style={{color: '#fff'}}>
                    {loading ? <CircularProgress size={25} style={{color: '#fff'}}/> : 'Save'}
                </Button>
            </Grid>
            <Notifications/>
        </Grid>
    );
};

export default ScanReceiverPage;
