export const menuItems = [
    {
        name: 'receivers',
        id: 'receivers',
        subItems: [
            {name: 'manage', id: 'manage'},
            {name: 'activity', id: 'activity'},
        ]
    },
    {
        name: 'dashboard',
        id: 'dashboard',
        subItems: [
            {name: 'Active Receivers', id: 'keyIndicators'}
        ]
    },
];

export const adminMenuItems = [
    {
        name: 'partners',
        id: 'partners',
        subItems: [
            {name: 'manage', id: 'manage'},
        ]
    },
    {
        name: 'invoices',
        id: 'invoices'
    }
];
