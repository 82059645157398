import React, {useEffect, useState} from 'react';
import {getStorageItem} from '../../helpers/authHelper';
import {Role} from '../../constants/roles';
import {useHistory} from 'react-router-dom';
import {Button, CircularProgress, FormControl, Grid, Input, InputLabel, Switch} from '@material-ui/core';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {generateInvoices} from './invoicesSlice';

const initialFormState = {
    acIds: '',
    acIdStart: '',
    acIdEnd: '',
    dryRun: false,
};

const Invoices = () => {

    const history = useHistory();
    const error = useAppSelector((state) => state.invoices.error);
    const loading = useAppSelector((state) => state.invoices.loading);
    const dispatch = useAppDispatch();
    const [formState, setFormState] = useState(initialFormState);

    useEffect(() => {
        const userPartnerId = getStorageItem('partnerId');
        if (userPartnerId !== Role.ADMIN.toString()) {
            history.push('/receivers');
        }
    }, [history]);

    const handleChange = (name: string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormState({
            ...formState,
            [name]: event.target.value,
        });
    };

    const handleGenerateInvoices = async () => {
        dispatch(generateInvoices(formState));
    }

    return <>
        <h1>Generate invoices</h1>

        <Grid container spacing={4}>
            <Grid item lg={12} xs={12}>
                <FormControl fullWidth error={error && formState.acIds.length === 0}>
                    <InputLabel htmlFor="acIds">Active Campaign IDs (comma separated)</InputLabel>
                    <Input id="acIds" value={formState.acIds}
                           onChange={(event) => handleChange('acIds', event)}/>
                </FormControl>
            </Grid>
            <Grid item lg={6} xs={12}>
                <FormControl fullWidth error={error && formState.acIdStart.length === 0}>
                    <InputLabel htmlFor="acIdStart">Active Campaign ID start</InputLabel>
                    <Input id="acIdStart" value={formState.acIdStart}
                           onChange={(event) => handleChange('acIdStart', event)}/>
                </FormControl>
            </Grid>
            <Grid item lg={6} xs={12}>
                <FormControl fullWidth error={error && formState.acIdEnd.length === 0}>
                    <InputLabel htmlFor="acIdEnd">Active Campaign ID end</InputLabel>
                    <Input id="acIdEnd" value={formState.acIdEnd}
                           onChange={(event) => handleChange('acIdEnd', event)}/>
                </FormControl>
            </Grid>
            <Grid item lg={12} xs={12}>
                <FormControl fullWidth>
                    <div>
                        Dry run (does not create Smartbill invoices and does not update AC
                        (produces email)
                    </div>
                    <Switch
                        checked={formState.dryRun}
                        onChange={(event) => setFormState({...formState, dryRun: event.target.checked})}
                        name="dryRun"
                        color="primary"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Button onClick={handleGenerateInvoices}
                        variant="contained" color="primary" style={{color: '#fff'}}>
                    {loading ? <CircularProgress size={25} style={{color: '#fff'}}/> : 'Generate invoices'}
                </Button>
            </Grid>
        </Grid>
    </>;
};

export default Invoices;