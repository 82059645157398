import 'date-fns';
import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks";
import {PartnersSelector} from "../../store/selectors/partnersSelectors";
import {FormControl, Grid, InputLabel, MenuItem, Select} from "@material-ui/core";
import {PartnerInterface} from "../../interfces/PartnersInterface";
import {getStorageItem} from "../../helpers/authHelper";
import {getHistory, changeDateTo, changeDateFrom, setSelectedPartnerId} from "../ReceiverHistory/receiverHistorySlice";
import {DateFromSelector, DateToSelector} from "../../store/selectors/receiversHistorySelectors";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import {formatDate} from "../../helpers/format";
import {Role} from '../../constants/roles';

const ADMIN_ROLE = Role.ADMIN.toString();

const ReceiverHistoryFilter = () => {
    const dateFrom = useAppSelector(DateFromSelector);
    const dateTo = useAppSelector(DateToSelector);
    const partnerIdFromToken = getStorageItem('partnerId');
    const [from, setFrom] = useState(dateFrom);
    const [to, setTo] = useState(dateTo);
    const [partnerId, setPartnerId] = useState(partnerIdFromToken === ADMIN_ROLE ? '*' : partnerIdFromToken);
    const dispatch = useAppDispatch();
    const partners = useAppSelector(PartnersSelector);
    useEffect(() => {
        if (partnerIdFromToken !== ADMIN_ROLE) {
            dispatch(getHistory(partnerIdFromToken));
        } else {
            dispatch(getHistory(partnerId));
        }
    }, [dispatch, from, to, partnerIdFromToken, partnerId])
    const handleSetPartner = (event: React.ChangeEvent<{ value: unknown }>) => {
        const partnerId = event.target.value as string;
        setPartnerId(partnerId);
        dispatch(setSelectedPartnerId(partnerId));
    }
    const handleSetFrom = (date: any) => {
        dispatch(changeDateFrom(formatDate(date)));
        setFrom(formatDate(date));
    }
    const handleSetTo = (date: any) => {
        dispatch(changeDateTo(formatDate(date)));
        setTo(formatDate(date));
    }
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={2} style={{ alignItems: 'center', maxWidth: '700px', width: '500px'}}>
                <Grid item xs={4}>
                    <KeyboardDatePicker
                        autoOk
                        disableToolbar
                        variant="inline"
                        format="MM.dd.yyyy"
                        margin="normal"
                        id="date-picker-from"
                        label="From"
                        value={from}
                        onChange={handleSetFrom}
                        KeyboardButtonProps={{
                            'aria-label': 'change date From',
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <KeyboardDatePicker
                        autoOk
                        disableToolbar
                        variant="inline"
                        format="MM.dd.yyyy"
                        margin="normal"
                        id="date-picker-to"
                        label="To"
                        value={to}
                        onChange={handleSetTo}
                        KeyboardButtonProps={{
                            'aria-label': 'change date To',
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    {partnerIdFromToken === 'admin' ?
                        <FormControl variant="filled" style={{width: 100, background: 'fff', margin: 5}}>
                            <InputLabel id="selectType">Partner</InputLabel>
                            <Select
                                onChange={handleSetPartner}
                                labelId="selectPartner"
                                id="selectPartner"
                                value={partnerId}
                            >
                                <MenuItem value={'*'}>*</MenuItem>
                                {partners.map((partner: PartnerInterface, index) => (
                                    <MenuItem key={`${index}-partner`}
                                              value={partner.partnerId}>{partner.name}</MenuItem>))}
                            </Select>
                        </FormControl> : <></>}
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    );
};

export default ReceiverHistoryFilter;
