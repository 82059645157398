import React from 'react';
import {NavLink, useHistory} from "react-router-dom";
import {IconButton} from "@material-ui/core";
import {ArrowBackIos, ArrowForwardIos} from "@material-ui/icons";
import {adminMenuItems, menuItems} from "../../constants/menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import {makeStyles} from "@material-ui/core/styles";
import {getStorageItem} from "../../helpers/authHelper";
import {Role} from '../../constants/roles';

const ADMIN_ROLE = Role.ADMIN.toString();

const useStyles = makeStyles((theme) => ({
    listItem: {
        color: '#000',
        pointerEvents: 'none',
        textAlign: 'center',
        textDecoration: 'none',
    },
    activeListItem: {
        pointerEvents: 'none',
        background: '#FF8753',
        color: '#fff',
        textAlign: 'center',
        textDecoration: 'none',
    },
    menuWrapper: {
        // height: '100%',
        position: 'fixed',
        left: '160px',
        background: 'lightgray',
        paddingTop: '90px',
        display: 'flex',
        flexDirection: 'column'
    }
}));
const SubMenu = ({showMenu, collapseMenu}: {showMenu: boolean, collapseMenu:() => void}):JSX.Element => {
    const classes = useStyles();

    const history = useHistory();
    const userPartnerId = getStorageItem('partnerId');
    const currentLocation = history.location.pathname;
    const allItems = userPartnerId === ADMIN_ROLE ? [...menuItems, ...adminMenuItems] : menuItems;
    const subItems = allItems.find((el) => el.name === currentLocation.split('/')[1])?.subItems;
    if (!subItems || (currentLocation.split('/').includes('receivers') && currentLocation.split('/').includes('partners'))) return <></>;
    const currentLocationParent = currentLocation.split('/')[1];



    return (
        <div className={classes.menuWrapper} style={{width: showMenu ? '150px' : '0px', height: showMenu ? '100%' : '0%', zIndex: 10}}>
            <div style={{textAlign: 'right'}}>
            <IconButton aria-label="arrow" color='primary' onClick={collapseMenu}>
                {showMenu ? <ArrowBackIos fontSize="small"/> : <ArrowForwardIos fontSize="small" />}
            </IconButton>
            </div>
            {showMenu && <List>
                {subItems?.map(({name, id}) => (
                    <NavLink key={id} to={`/${currentLocationParent}/${id}`} style={{textDecoration: "none"}} activeStyle={{
                        fontWeight: "bold",
                        color: "red"
                    }}>
                        <ListItem button
                                  className={currentLocation.split('/').includes(id) ? classes.activeListItem : classes.listItem}>
                            <ListItemText primary={name} />
                        </ListItem>
                    </NavLink>
                ))}
            </List>}
        </div>
    );
};

export default SubMenu;
