import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import Login from '../components/auth/Login';
import GuardRoute from './GuardRoute';
import Receivers from '../components/Receivers';
import MainLayout from '../Layouts/MainLayout';
import Partners from '../components/Partners';
import Dashboard from '../components/Dashboard/Dashboard';
import ScanReceiverPage from '../components/Receivers/ScanReceiverPage';
import ReceiverActivity from '../components/ReceiverActivity';
import Invoices from '../components/Invoices';

const AppRouter = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/login" component={Login}/>
                <GuardRoute component={ScanReceiverPage} exact={true} path={"/deviceInfo"}/>
                <Route exact path="/">
                    <Redirect to="/receivers"/>
                </Route>
                <MainLayout>
                    <div>
                        <Switch>
                            <Route exact path="/receivers">
                                <Redirect to="/receivers/manage"/>
                            </Route>
                            <GuardRoute component={Receivers} path="/receivers/manage" exact={true}/>
                            {/*<GuardRoute component={ReceiverHistory} path="/receivers/history" exact={true}/>*/}
                            <GuardRoute component={ReceiverActivity} path="/receivers/activity" exact={true}/>
                            <Route exact path="/partners">
                                <Redirect to="/partners/manage"/>
                            </Route>
                            <GuardRoute component={Partners} path="/partners/manage" exact={true}/>
                            <GuardRoute component={Invoices} path="/invoices" exact={true}/>
                            <Route exact path="/dashboard">
                                <Redirect to="/dashboard/keyIndicators"/>
                            </Route>
                            <GuardRoute component={Dashboard} path="/dashboard/keyIndicators" exact={true}/>
                        </Switch>
                    </div>
                </MainLayout>
                <Route path="">
                    <>Not much</>
                </Route>
            </Switch>
        </Router>
    );
};

export default AppRouter;
