import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {changePage, changeRowsPerPage, changeSorting, deleteReceiver, getReceivers} from './receiversSlice';
import MainTable from '../Table/Table';
import {
    LoadingSelector,
    PageSelector,
    PageSizeSelector,
    ReceiversSelector,
    SortBySelector,
    SortDirectionSelector,
    TotalSizeSelector,
} from '../../store/selectors/receiversSelector';
import {LinearProgress} from '@material-ui/core';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import ReceiverEditFormDialog from '../forms/EditReceiverFormDialog';
import {
    actionGetChangeList, actionRequestDiagnostics,
    ChangeListErrorSelector,
    ChangeListIsPendingSelector,
    ChangeListSelector,
} from './receiver-change-list-slice';
import {ChangeListComponent} from './change-list-component';
import {Page} from '../../constants/page';

const Receivers = () => {
    const dispatch = useAppDispatch();
    const [openDelete, setOpenDelete] = useState(false);
    const [receiverId, setReceiverId] = useState('');
    const [openEdit, setOpenEdit] = useState(false);
    const [changeListReceiverId, setChangeListReceiverId] = useState<string | null>(null);
    const [currentReceiver, setCurrentReceiver] = useState(null);
    const [type, setType] = useState('');
    const receivers = useAppSelector(ReceiversSelector);
    const page = useAppSelector(PageSelector);
    const pageSize = useAppSelector(PageSizeSelector);
    const sortBy = useAppSelector(SortBySelector);
    const sortDirection = useAppSelector(SortDirectionSelector);
    const loading = useAppSelector(LoadingSelector);
    const totalItems = useAppSelector(TotalSizeSelector);

    const isChangeListPending = useAppSelector(ChangeListIsPendingSelector);
    const getChangeListError = useAppSelector(ChangeListErrorSelector);
    const changeList = useAppSelector(ChangeListSelector);

    const handleSort = (property: string) => {
        dispatch(changeSorting(property));
    };
    const handleSetRowsPerPage = (rowsPerPage: number) => {
        dispatch(changeRowsPerPage(rowsPerPage));
    };
    const handleSetPage = (pageNumber: number) => {
        dispatch(changePage(pageNumber));
    };
    const handleEdit = ({itemId, type}: { itemId: string, type: string }) => {
        const searchReceiver = receivers.find((el) => el.receiverId === itemId && el.type === type);
        if (searchReceiver) {
            // @ts-ignore
            setCurrentReceiver(searchReceiver);
            setOpenEdit(true);
        }
    };
    const getItemChangeList = (opts: { receiverId: string, partnerId: string }) => {
        dispatch(actionGetChangeList(opts));
        setChangeListReceiverId(opts.receiverId);
    };

    const getDiagnostics = (opts: { receiverId: string, partnerId: string }) => {
        dispatch(actionRequestDiagnostics(opts));
    }

    const onCloseChangeList = () => {
        setChangeListReceiverId(null);
    };

    const cancelEdit = () => {
        setCurrentReceiver(null);
        setOpenEdit(false);
    };
    const handleDelete = ({itemId, type}: { itemId: string, type: string }) => {
        setOpenDelete(true);
        setReceiverId(itemId);
        setType(type);
    };
    const confirmDelete = () => {
        setOpenDelete(false);
        dispatch(deleteReceiver(receiverId, type));
    };

    const closeConfirm = () => {
        setOpenDelete(false)
    }
    useEffect(() => {
        dispatch(getReceivers());
    }, [dispatch]);

    return (
        <div>
            <div style={{height: '10px'}}>
                {loading && <LinearProgress color="primary"/>}
            </div>
            <ChangeListComponent isOpen={changeListReceiverId !== null}
                                 onClose={onCloseChangeList}
                                 receiverId={changeListReceiverId}
                                 data={changeList}
                                 isPending={isChangeListPending}
                                 error={getChangeListError}/>
            <MainTable pageName={Page.RECEIVERS_MANAGE}
                       loading={loading}
                       data={receivers}
                       page={page}
                       pageSize={pageSize}
                       sortBy={sortBy}
                       setRowsPerPage={handleSetRowsPerPage}
                       sortDirection={sortDirection}
                       onRequestSort={handleSort}
                       totalCount={totalItems}
                       setPage={handleSetPage}
                       editItem={handleEdit}
                       deleteItem={handleDelete}
                       getItemChangeList={getItemChangeList}
                       requestDiagnostics={getDiagnostics}
            />
            <ConfirmDialog handleConfirm={confirmDelete} handleClose={closeConfirm} open={openDelete}/>
            <ReceiverEditFormDialog handleOpen={cancelEdit} receiver={currentReceiver} open={openEdit}/>
        </div>
    );
};
export default Receivers;
