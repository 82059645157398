import {createSlice} from '@reduxjs/toolkit';
import {AppThunk} from '../../store/store';
import {ReceiverInterface, ReceiversInterface} from '../../interfces/ReceiversInterface';
import {requestDeleteReceiver, requestReceivers, requestUpsertReceiver} from '../../provider/receiversProvider';
import {getStorageItem} from '../../helpers/authHelper';
import {addErrorNotification, addNotification} from '../Notifications/notificationsSlice';
import {DEFAULT_PAGE_SIZE} from '../../helpers/constants';

const initialState: ReceiversInterface = {
    data: [],
    isActive: 'All',
    pageSize: DEFAULT_PAGE_SIZE,
    pageCount: 0,
    page: 0,
    total: 0,
    query: '',
    sortBy: 'type',
    sortDirection: 'desc',
    receiverEdited: new Date(),
    loading: false,
    selectedPartnerId: null,
};

export const receiversSlice = createSlice({
    name: 'receivers',
    initialState,
    reducers: {
        setReceivers: (state, action) => {
            state.data = action.payload.data;
            state.pageCount = action.payload.pageCount;
            state.total = action.payload.total;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setIsActive: (state, action) => {
            state.isActive = action.payload;
        },
        setSortBy: (state, action) => {
            state.sortBy = action.payload;
        },
        setSortDirection: (state, action) => {
            state.sortDirection = action.payload;
        },
        setRowsPerPage: (state, action) => {
            state.pageSize = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setQuery: (state, action) => {
            state.query = action.payload;
        },
        setReceiverEdited: (state) => {
            state.receiverEdited = new Date();
        },
        setSelectedPartnerId: (state, action) => {
            state.selectedPartnerId = action.payload;
        },
    },
});

export const {
    setReceivers,
    setLoading,
    setSortBy,
    setSortDirection,
    setRowsPerPage,
    setPage,
    setQuery,
    setReceiverEdited,
    setSelectedPartnerId
} = receiversSlice.actions;

export const getReceivers = (): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void,
    getState
) => {
    dispatch(setLoading(true));
    const {isActive, query, sortBy, sortDirection, page, pageSize, selectedPartnerId} = getState().receivers;
    const partnerId = selectedPartnerId ?? getStorageItem('partnerId');
    try {
        if (!partnerId) return;

        const response = await requestReceivers({partnerId, isActive, page, pageSize, sortBy, sortDirection, query});
        // @ts-ignore
        dispatch(setReceivers(response.data));

    } catch (e) {
        dispatch(addErrorNotification(e));
    }
    dispatch(setLoading(false));
};

export const changeSorting = (property: string): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void,
    getState
) => {

    const {sortDirection} = getState().receivers;
    if (sortDirection === 'asc') {
        dispatch(setSortDirection('desc'))
    } else {
        dispatch(setSortDirection('asc'));
    }
    dispatch(setLoading(true));
    dispatch(setSortBy(property));
    dispatch(getReceivers());
}

export const handleQuery = (query: string): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void
) => {
    dispatch(setPage(0));
    dispatch(setLoading(true));
    dispatch(setQuery(query));
    dispatch(getReceivers());
}
export const changeRowsPerPage = (rowsPerPage: number): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void
) => {
    dispatch(setLoading(true));
    dispatch(setRowsPerPage(rowsPerPage));
    dispatch(getReceivers());
}

export const changePage = (pageNumber: number): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void
) => {
    dispatch(setLoading(true));
    dispatch(setPage(pageNumber));
    dispatch(getReceivers());
}

export const handlerSelectedPartnerId = (value: string): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void
) => {
    dispatch(setLoading(true));
    dispatch(setSelectedPartnerId(value));
    dispatch(getReceivers());
}

export const upsertReceiver = ({formData, previousPartnerId}: { formData: ReceiverInterface, previousPartnerId: string }): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void
) => {
    dispatch(setLoading(true));
    try {
        const partnerId = getStorageItem('partnerId');
        if (!partnerId) return false;
        if (formData.partnerId === '') {
            formData.partnerId = partnerId;
        }
        const result = await requestUpsertReceiver(formData, previousPartnerId);
        if (result.status === 200) {
            dispatch(addNotification('success', 'receiver added successfully'));
            dispatch(setReceiverEdited());
            dispatch(getReceivers())
            dispatch(setLoading(false))
        }
    } catch (e) {
        dispatch(addErrorNotification(e));
        dispatch(setLoading(false));
    }

}

export const deleteReceiver = (receiverId: string, type: string): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void,
    getState
) => {
    dispatch(setLoading(true));
    try {
        const receivers = [...getState().receivers.data];
        const pageCount = getState().receivers.pageCount;
        const currentReceiverIndex = receivers.findIndex((el) => el.receiverId === receiverId && el.type === type);
        const partnerId = receivers[currentReceiverIndex]?.partnerId;
        if (!partnerId) {
            return false;
        }
        await requestDeleteReceiver(receiverId, partnerId, type);
        receivers.splice(currentReceiverIndex, 1);
        dispatch(setReceivers({data: receivers, pageCount}));
        dispatch(addNotification('success', 'receiver deleted successfully'));
    } catch (e) {
        dispatch(addErrorNotification(e));
    }
    dispatch(setLoading(false));
}
export default receiversSlice.reducer;
