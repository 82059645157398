import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, {AlertProps} from '@material-ui/lab/Alert';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {useAppDispatch, useAppSelector} from "../../hooks";
import {notificationSelector} from "../../store/selectors/notificationsSelectors";
import {removeNotifications} from "./notificationsSlice";

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function Notifications() {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const notification = useAppSelector(notificationSelector);

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(removeNotifications());
    };
    if (notification.message.length === 0) {
        return <></>
    }
    return (
        <div className={classes.root}>
            {notification.type === 'success' &&
            <Snackbar open={notification.message.length > 0} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    {notification.message[0]}
                </Alert>
            </Snackbar>}
            {notification.type === 'error' &&
            <Snackbar open={notification.message.length > 0} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    <ul>
                        {notification.message.map((el, index) => <li key={index}>{el}</li>)}
                    </ul>

                </Alert>
            </Snackbar>}
        </div>
    );
}
