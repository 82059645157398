import {
    createAsyncThunk,
    createSlice,
    isFulfilled,
    isPending,
    isRejectedWithValue,
    PayloadAction,
} from '@reduxjs/toolkit';
import http from '../../provider/axios';
import {getStorageItem} from '../../helpers/authHelper';
import {RootState} from '../../store/store';
import {GetReceiverActivityStatsResponse} from '../../interfces/api/get-receiver-activity-stats-response';
import {ReceiverActivityStats} from '../../interfces/api/receiver-activity-stats';
import {DEFAULT_PAGE_SIZE} from '../../helpers/constants';

export interface ReceiverActivityStatsState {
    isPending: boolean;
    error?: string | null;
    data: ReceiverActivityStats[];
    page: number;
    size: number;
    partnerId: string;
    year: number;
    month: number;
}

export interface GetReceiverActivityStatsParams {
    partnerId: string;
    year: number;
    month: number;
    page: number;
    size: number;
}

const initialState: ReceiverActivityStatsState = {
    isPending: false,
    error: null,
    data: [],
    page: 0,
    size: DEFAULT_PAGE_SIZE,
    partnerId: getStorageItem('partnerId') || '*',
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
};
if (initialState.partnerId === 'admin') {
    initialState.partnerId = '*';
}

// TODO: display the errors if any (need to update all errors in the app and update axios to not throw errors)

export const actionGetReceiverActivityStats = createAsyncThunk<GetReceiverActivityStatsResponse, GetReceiverActivityStatsParams>
('/get-receiver-activity-stats', async (props) => {
    const token = getStorageItem('token');
    const {partnerId, year, month, size = DEFAULT_PAGE_SIZE, page} = props;

    const requestPartnerId = partnerId ?? getStorageItem('partnerId');

    const response = await http.get<GetReceiverActivityStatsResponse>(`/private/analytics/${requestPartnerId}/activity-stats`, {
            headers: {
                'Authorization': token,
            },
            params: {
                year: year,
                month: month,
                size: size,
                page: page,
            },
        },
    );
    return response.data;
});

export const receiverActivityStatsSlice = createSlice({
    name: 'receiver-activity-stats',
    initialState,
    reducers: {
        setPartnerId: (state: ReceiverActivityStatsState, action: PayloadAction<string>) => {
            state.partnerId = action.payload;
        },
        setYear: (state: ReceiverActivityStatsState, action: PayloadAction<number>) => {
            state.year = action.payload;
        },
        setMonth: (state: ReceiverActivityStatsState, action: PayloadAction<number>) => {
            state.month = action.payload;
        },
        setPage: (state: ReceiverActivityStatsState, action: PayloadAction<number>) => {
            state.page = action.payload;
        },
        setSize: (state: ReceiverActivityStatsState, action: PayloadAction<number>) => {
            state.size = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(isFulfilled(actionGetReceiverActivityStats), (state, {payload}) => {
            if (payload.data) {
                state.data = payload.data;
            }
            if (payload.errors && payload.errors.length) {
                state.error = payload.errors[0].message;
            }
            state.isPending = false;
        });

        builder.addMatcher(isPending(actionGetReceiverActivityStats), (state) => {
            state.data = [];
            state.error = null;
            state.isPending = true;
        });

        builder.addMatcher(isRejectedWithValue(actionGetReceiverActivityStats), (state, {payload}) => {
            state.data = [];
            state.error = String(payload);
            state.isPending = false;
        });
    },
});

export const {
    setPartnerId,
    setYear,
    setMonth,
    setPage,
    setSize,
} = receiverActivityStatsSlice.actions;

export const ActivityStatsSelector = (state: RootState) => state.receiverActivityStats.data;
export const ActivityStatsIsPendingSelector = (state: RootState) => state.receiverActivityStats.isPending;
export const ActivityStatsErrorSelector = (state: RootState) => state.receiverActivityStats.error;
export const ActivityStatsPageSelector = (state: RootState) => state.receiverActivityStats.page;
export const ActivityStatsSizeSelector = (state: RootState) => state.receiverActivityStats.size;
export const ActivityStatsPartnerIdSelector = (state: RootState) => state.receiverActivityStats.partnerId;
export const ActivityStatsYearSelector = (state: RootState) => state.receiverActivityStats.year;
export const ActivityStatsMonthSelector = (state: RootState) => state.receiverActivityStats.month;
export default receiverActivityStatsSlice.reducer;