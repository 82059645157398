import {createSlice} from '@reduxjs/toolkit';
import {AppThunk} from '../../store/store';
import {AuthSlice} from '../../interfces/UserInterface';
import {loginUserRequest} from '../../provider/authProvider';
import {removeStorageItem, saveToken} from '../../helpers/authHelper';
import {History} from 'history';
import {getPartners} from '../Partners/partnersSlice';

const initialState: AuthSlice = {
    isLoggedIn: false,
    loginError: '',
    isAdmin: false,
    partnerId: null,
    name: '',
    loading: false
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess: (state, action) => {
            state.isLoggedIn = true;
            state.isAdmin = action.payload.isAdmin;
            state.partnerId = action.payload.partnerId;
        },
        loginFailed: (state, action) => {
            state.isLoggedIn = false;
            state.loginError = action.payload;
        },
        logout: (state) => {
            state.isLoggedIn = false;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    },
});

export const {loginSuccess, logout, setLoading, loginFailed} = authSlice.actions;

export const loginUser = (email: string, password: string, history: History<any> | string[], backRoute: string): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void,
) => {
    dispatch(setLoading(true));
    dispatch(loginFailed(''));
    const isMobile = true;
    if (!email.length && !password.length) {
        dispatch(loginFailed('Email and Password are empty!'));
        dispatch(setLoading(false));
        return false;
    }
    try {
        const response = await loginUserRequest({email, password, isMobile});
        const {authenticationToken, authenticationTokenType, isAdmin, partnerId} = response.data.data;
        saveToken(authenticationToken, authenticationTokenType);
        if (isAdmin) {
            dispatch(getPartners());
        }
        dispatch(loginSuccess({isAdmin, partnerId}));
        const redirectTo = backRoute ? backRoute : '/Receivers'
        history.push(redirectTo);
    } catch (error) {
        const errorText = error && error.response ? error.response.data.errors[0].message.replace('LoginUserRequestBody.','') : 'something went wrong'
        dispatch(loginFailed(errorText));
    }


    dispatch(setLoading(false));
};
export const logoutUser = () => {
    removeStorageItem('token');
    removeStorageItem('partnerId');
};

export default authSlice.reducer;
