import {RootState} from "../store";

export const ReceiverHistorySelector = (state: RootState) => state.receiverHistory.data;
export const PageSelector = (state: RootState) => state.receiverHistory.page;
export const SortBySelector = (state: RootState) => state.receiverHistory.sortBy;
export const SortDirectionSelector = (state: RootState) => state.receiverHistory.sortDirection;
export const PageSizeSelector = (state: RootState) => state.receiverHistory.pageSize;
export const TotalPagesSelector = (state: RootState) => state.receiverHistory.pageCount;
export const TotalSizeSelector = (status: RootState) => status.receiverHistory.total;
export const DateFromSelector = (state: RootState) => state.receiverHistory.dateFrom;
export const DateToSelector = (state: RootState) => state.receiverHistory.dateTo;
export const LoadingSelector = (state: RootState) => state.receiverHistory.loading;
