import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {ColumnsInterface, TABLE_HEADERS} from '../../constants/tableHeaders';
import {IconButton, Tooltip} from '@material-ui/core';
import {ArrowRight} from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import HistoryIcon from '@material-ui/icons/History';
import DiagnosticsIcon from '@material-ui/icons/SettingsEthernet';
import {formatDateForDisplay} from '../../helpers/format';
import useWindowDimensions from '../../hooks';
import {getBooleanStorageItem, getStorageItem} from '../../helpers/authHelper';
import {Page} from '../../constants/page';
import {Role} from '../../constants/roles';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    th: {
        padding: 0,
        paddingStart: '0.5em',
        maxWidth: '8rem',
    },
    td: {
        padding: 0,
        paddingStart: '0.5rem',
    }
});

interface MainTableInterface {
    pageName: Page,
    loading: boolean,
    data: any[],
    page: number,
    pageSize: number;
    setPage: (page: number) => void;
    setRowsPerPage: (rowsPerPage: number) => void;
    sortBy?: string,
    sortDirection: 'asc' | 'desc',
    onRequestSort: (property: string) => void,
    editItem?: ({itemId, type}: { itemId: string, type: string }) => void,
    deleteItem?: ({itemId, type}: { itemId: string, type: string }) => void,
    getItemChangeList?: ({receiverId, partnerId}: { receiverId: string, partnerId: string }) => void,
    requestDiagnostics?: ({receiverId, partnerId}: { receiverId: string, partnerId: string }) => void,
    totalCount: number
}

const humanReadableBoolean = (value: boolean) => (value ? 'on' : 'off');

// @ts-ignore
const addForwardedStatus = (row: any) => <>
    <Tooltip title={row.forwardingStatus + ': ' + row.forwardingMessage}>
        <ArrowRight fontSize={'large'} htmlColor={row.forwardingStatus === 'delivered' ? 'green' : 'red'}/>
    </Tooltip><br/>
</>;

function getKeepAliveStatus(row: any) {
    const kpis = `GA: ${row.ga ?? 'N/A'}, PN: ${row.pn ?? 'N/A'}, SW: ${row.sw ?? 'N/A'}, KA: ${row.keepAliveCount}`;
    const date = row.lastKeepAliveCallDate ? formatDateForDisplay(row.lastKeepAliveCallDate) : 'Never';
    return `${date}, ${kpis}`;
}

const getValue = (column: ColumnsInterface, row: any) => {
    if (column.name === 'isActive'
        || column.name === 'hasFeatureSMSAutoReply') {

        return humanReadableBoolean(row[column.name]);
    }
    return row[column.name];
};

export default function MainTable({
                                      pageName,
                                      data,
                                      loading,
                                      page,
                                      pageSize,
                                      setPage,
                                      setRowsPerPage,
                                      sortBy,
                                      sortDirection,
                                      onRequestSort,
                                      totalCount,
                                      deleteItem,
                                      editItem,
                                      getItemChangeList,
                                      requestDiagnostics,
                                  }: MainTableInterface) {
    const classes = useStyles();
    const {height} = useWindowDimensions();

    const partnerId = getStorageItem('partnerId');
    const hasPartnerFeatureSMSAutoReply = getBooleanStorageItem('hasPartnerFeatureSMSAutoReply');
    const columns: ColumnsInterface[] = TABLE_HEADERS[pageName].filter(column => column.name !== 'hasFeatureSMSAutoReply' || hasPartnerFeatureSMSAutoReply);

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: { target: { value: React.ReactText } }) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const createSortHandler = (property: string) => () => {
        onRequestSort(property);
    };
    const itemName = (row: any) => {
        if (pageName === Page.RECEIVERS_MANAGE) {
            return {itemId: row.receiverId, type: row.type};
        }
        if (pageName === Page.PARTNERS_MANAGE) {
            return {itemId: row.partnerId, type: ''};
        }
        return {itemId: '', type: ''};
    };
    const handleDelete = (row: any): void => {
        const {itemId, type} = itemName(row);
        deleteItem && deleteItem({itemId, type});
    };
    const handleEdit = (row: any) => {
        editItem && editItem(itemName(row));
    };

    const getChangeList = (row: any) => {
        getItemChangeList && getItemChangeList({
            receiverId: row.receiverId,
            partnerId: row.partnerId,
        });
    };

    const getDiagnostics = (row: any) => {
        requestDiagnostics && requestDiagnostics({
            receiverId: row.receiverId,
            partnerId: row.partnerId,
        });
    };


// @ts-ignore
    return (
        <Paper className={classes.root}>
            <TableContainer style={{height: height - 160, minHeight: '500px'}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => {
                                if (column.sortable) {
                                    return (
                                        <TableCell
                                            width={column.width}
                                            className={classes.th}
                                            key={column.name}
                                            sortDirection={sortBy === column.name ? sortDirection : false}>
                                            <TableSortLabel
                                                active={sortBy === column.name}
                                                direction={sortBy === column.name ? sortDirection : 'desc'}
                                                onClick={createSortHandler(column.name)}>
                                                {column.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    );
                                } else {
                                    return (
                                        <TableCell key={column.name} width={column.width}>
                                            {column.label}
                                        </TableCell>
                                    );
                                }
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? <></> : !data || data.length === 0 ?
                            <TableRow>
                                <TableCell colSpan={columns.length} align="center" className={classes.td}>
                                    <Typography variant="h5" style={{marginTop: 10}}>
                                        No items to display
                                    </Typography>
                                </TableCell>
                            </TableRow> : data.map((row, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        {columns.map((column, index) => {
                                            // @ts-ignore
                                            const value = getValue(column, row);
                                            if (column.name === 'actions' && columns.map(el => el.name).includes('actions')) {
                                                return <TableCell key={index} className={classes.td}>
                                                    <IconButton onClick={() => handleDelete(row)}
                                                                aria-label="delete" style={{color: 'red'}}>
                                                        <DeleteIcon fontSize="small"/>
                                                    </IconButton>
                                                    <IconButton onClick={() => handleEdit(row)}
                                                                aria-label="edit"
                                                                color="primary">
                                                        <CreateIcon fontSize="small"/>
                                                    </IconButton>
                                                    <IconButton onClick={() => getChangeList(row)}
                                                                aria-label="edit"
                                                                color="primary">
                                                        <HistoryIcon fontSize="small"/>
                                                    </IconButton>
                                                    {['voipstudio', 'mobile'].includes(row.type) &&
                                                        <IconButton onClick={() => getDiagnostics(row)}
                                                                    aria-label="edit"
                                                                    color="primary">
                                                            <DiagnosticsIcon fontSize="small"/>
                                                        </IconButton>}

                                                </TableCell>;
                                            }

                                            if (column.name === 'createdAt' && pageName === Page.RECEIVER_ACTIVITY) {
                                                return (
                                                    <Tooltip
                                                        key={column.name}
                                                        title={row.forwardingStatus + ': ' + row.forwardingMessage}>
                                                        <TableCell className={classes.td}>
                                                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                                                <ArrowRight fontSize={'large'}
                                                                            htmlColor={row.forwardingStatus === 'delivered' ? 'green' : 'red'}/>
                                                                {formatDateForDisplay(value)}
                                                            </div>
                                                        </TableCell>
                                                    </Tooltip>
                                                );
                                            }

                                            if ([Role.ADMIN.toString(), Role.WHOCALLS_USER.toString()].includes(partnerId)) {
                                                if (column.name === 'receiverId' && pageName === Page.RECEIVER_ACTIVITY) {
                                                    return (
                                                        <Tooltip key={column.name} title={row.originalMessage ?? ''}>
                                                            <TableCell className={classes.td}>
                                                                {value}
                                                            </TableCell>
                                                        </Tooltip>
                                                    );
                                                }

                                                if (column.name === 'receiverId' && pageName === Page.RECEIVERS_MANAGE) {
                                                    return (
                                                        <Tooltip
                                                            arrow={true}
                                                            key={column.name}
                                                            title={getKeepAliveStatus(row)}>
                                                            <TableCell className={classes.td}>
                                                                {value}
                                                            </TableCell>
                                                        </Tooltip>
                                                    );
                                                }
                                            }

                                            return (
                                                <TableCell key={column.name} className={classes.td}>
                                                    {column.name === 'since' || column.name === 'createdAt' ? formatDateForDisplay(value) : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[20, 100, 200, 500, 1000, 10000]}
                component="div"
                count={totalCount}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
