export enum Page {
    RECEIVERS_MANAGE = '/receivers/manage',
    PARTNERS_MANAGE = '/partners/manage',
    RECEIVER = '/receiver',
    INVOICES = '/invoices',
    RECEIVER_ACTIVITY = '/receivers/activity',
    DASHBOARD_KPI = '/dashboard/keyIndicators',
    RECEIVER_HISTORY = '/receivers/history',
}

export class PageManager {
    static mainPages: Page[] = [Page.RECEIVERS_MANAGE, Page.PARTNERS_MANAGE, Page.INVOICES, Page.DASHBOARD_KPI, Page.RECEIVER, Page.RECEIVER_ACTIVITY];

    static getPage = (pathname: string): Page=> {
        const pages = Object.values(Page);
        const page = pages.find((page) => page === pathname);
        return page || Page.RECEIVERS_MANAGE;
    };
}


