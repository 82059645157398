import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import {NavLink, useHistory} from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {makeStyles} from '@material-ui/core/styles';
import {useAppDispatch} from '../../hooks';
import {logoutUser} from '../auth/authSlice';
import {Button, Typography} from '@material-ui/core';
import {adminMenuItems, menuItems} from '../../constants/menu';
import {getStorageItem} from '../../helpers/authHelper';
import {Role} from '../../constants/roles';

const ADMIN_ROLE = Role.ADMIN.toString();
const DRAWER_WIDTH = 160;

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: DRAWER_WIDTH,
        flexShrink: 0,
    },
    drawerPaper: {
        overflowX: 'hidden',
        width: DRAWER_WIDTH,
        background: '#30031F'
    },
    listItem: {
        color: '#000',
        pointerEvents: 'none',
        background: '#fff',
        margin: 10,
        borderRadius: 10,
        textAlign: 'center',
        textDecoration: 'none',
        width: '135px'
    },
    activeListItem: {
        pointerEvents: 'none',
        background: '#FF8753',
        color: '#fff',
        margin: 10,
        borderRadius: 10,
        textAlign: 'center',
        textDecoration: 'none',
        width: '135px'
    },
    listItemButton: {
        color: '#000',
        background: '#fff',
        margin: 10,
        borderRadius: 10,
        textAlign: 'center',
        textDecoration: 'none',
        width: '135px',
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: "#FFF"
        }
    },
    toolbar: theme.mixins.toolbar,
    titleTypography: {
        fontWeight: 'bold',
        color: '#fff',
        textAlign: 'center',
        paddingTop: 15
    },
    adminText: {
        textAlign: 'center'
    }
}));
const Menu = ({openSubMenu} : {openSubMenu: () => void}) => {
    const classes = useStyles();
    const history = useHistory();
    const userPartnerId = getStorageItem('partnerId');
    const dispatch = useAppDispatch();
    const currentLocation = history.location.pathname;
    const allItems = userPartnerId === ADMIN_ROLE ? [...menuItems, ...adminMenuItems] : menuItems;
    const handleLogOut = () => {
        dispatch(logoutUser);
        history.push('/login');
    }
    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
            anchor="left"
        >
            <div className={classes.toolbar}>
                <Typography className={classes.titleTypography} variant='h5'>WHOCALLS</Typography>
                <Typography className={classes.adminText} variant='body1' color="primary">{userPartnerId}</Typography>
            </div>
            <Divider/>
            <List>
                {allItems.map(({name, id}) => {
                    let itemClass = classes.listItem;
                    console.log('currentLocation', currentLocation);
                    console.log('name', name);
                    if (currentLocation.includes(name)) {
                        itemClass = classes.activeListItem;
                    }
                    return (
                        <NavLink key={id} to={`/${name}`} onClick={openSubMenu} style={{textDecoration: "none"}} activeStyle={{
                            fontWeight: "bold",
                            color: "red"
                        }}>
                            <ListItem button
                                      className={itemClass}>
                                <ListItemText primary={name}/>
                            </ListItem>
                        </NavLink>
                    )
                })}
                <NavLink to={{pathname:"https://whocalls.info/terms-of-service/"}} target="_blank" style={{textDecoration: 'none'}}>
                    <ListItem button
                              className={classes.listItem} >
                        <ListItemText primary="Terms of service"/>
                    </ListItem>
                </NavLink>
            </List>
            <Divider/>
            <Button className={classes.listItemButton} onClick={handleLogOut}> Log Out </Button>
        </Drawer>
    );
};

export default Menu;
