import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejectedWithValue} from '@reduxjs/toolkit';
import http from '../../provider/axios';
import {ReceiverChangeList} from '../../interfces/api/receiver-change-list';
import {ChangeListResponse} from '../../interfces/api/change-list-response';
import {getStorageItem} from '../../helpers/authHelper';
import {RootState} from '../../store/store';

export interface ReceiverChangeListState {
    isPending: boolean;
    error?: string | null;
    data: ReceiverChangeList[];
}

const initialState: ReceiverChangeListState = {
    isPending: false,
    data: [],
};

export const actionGetChangeList = createAsyncThunk<ChangeListResponse, {partnerId: string, receiverId: string}>
('/getChangeList', async (opts) => {
    const {partnerId, receiverId} = opts;
    const token = getStorageItem('token');
    const response = await http.get<ChangeListResponse>(`/private/partners/${partnerId}/receiver/${receiverId}/change-list`, {
            headers: {
                'Authorization': token,
            },
        },
    );
    return response.data;
});

export const actionRequestDiagnostics = createAsyncThunk<any, {partnerId: string, receiverId: string}>
('/requestDiagnostics', async (opts) => {
    const {partnerId, receiverId} = opts;
    const token = getStorageItem('token');
    const response = await http.get<ChangeListResponse>(`/private/partners/${partnerId}/receiver/${receiverId}/diagnostics`, {
            headers: {
                'Authorization': token,
            },
        },
    );
    return response.data;
});

export const changeListSlice = createSlice<ReceiverChangeListState, any>({
    name: 'changeList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(isFulfilled(actionGetChangeList), (state, {payload}) => {
            if (payload.data) {
                state.data = payload.data;
            }
            if (payload.errors && payload.errors.length) {
                state.error = payload.errors[0].message;
            }

            state.isPending = false;
        });

        builder.addMatcher(isPending(actionGetChangeList), (state) => {
            state.data = [];
            state.error = null;
            state.isPending = true;
        });

        builder.addMatcher(isRejectedWithValue(actionGetChangeList), (state, {payload}) => {
            state.data = [];
            state.error = String(payload);
            state.isPending = false;
        });
    },
});

export const ChangeListSelector = (state: RootState) => state.receiverChangeList.data;
export const ChangeListIsPendingSelector = (state: RootState) => state.receiverChangeList.isPending;
export const ChangeListErrorSelector = (state: RootState) => state.receiverChangeList.error;
export default changeListSlice.reducer;