import React, {useState, useEffect} from 'react';
import {Button, CircularProgress, FormControl, Grid, TextField, Typography} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import {useAppDispatch, useAppSelector} from "../../hooks";
import {loginUser} from "./authSlice";
import {useHistory} from "react-router-dom";
import {LoadingSelector, LoginErrorSelector} from "../../store/selectors/loginSelector";
import {getStorageItem} from "../../helpers/authHelper";

const Login = () => {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useAppDispatch();
    const error = useAppSelector(LoginErrorSelector);
    const loading = useAppSelector(LoadingSelector);
    const token = getStorageItem('token');
    useEffect(() => {
        if (token) {
            history.push('receivers')
        }
    }, [dispatch, token, history]);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, type: string) => {
        if (type === 'email') {
            setEmail(event.target.value);
        }
        if (type === 'password') {
            setPassword(event.target.value);
        }
    }
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const backRoute = history.location.state?.from;

        dispatch(loginUser(email, password, history, backRoute));
    }
    return (
        <div style={{height: '100%', minHeight: '500px', marginTop: '50px'}}>
            <form onSubmit={handleSubmit}>
               <Grid container spacing={4} justifyContent={'center'}>
                <Grid item xs={10}>
                     <Typography variant="h4" color="primary" style={{textAlign: 'center'}}>Welcome to WhoCalls</Typography>
                </Grid>
               <Grid item xs={10} lg={4} md={4} style={{textAlign: 'center'}}>
                   <FormControl fullWidth>
                       <TextField
                        label="Email"
                        id="email"
                        value={email}
                        onChange={(event) => handleChange(event, 'email')}
                        type="email"
                         />
                   </FormControl>
               </Grid>
               <div style={{width: '100%'}} />
               <Grid item xs={10} lg={4} md={4} style={{textAlign: 'center'}}>
                   <FormControl fullWidth>
                       <TextField
                        label="Password"
                        id="password"
                        value={password}
                        onChange={(event) => handleChange(event, 'password')}
                        type="password"
                        />
                   </FormControl>
               </Grid>
               <div style={{width: '100%'}} />
               <Grid item xs={10} lg={4} md={4} style={{textAlign: 'center'}}>
                   <FormControl fullWidth>
                   <Button type="submit" style={{background: '#FF8753', color: '#fff'}}>
                    {loading ? <CircularProgress size={25} style={{color: '#fff'}} /> : 'Log in'}
                    </Button>
                   </FormControl>
               </Grid>
               <div style={{width: '100%'}} />
               <Grid item xs={10} style={{textAlign: 'center'}}>
                    <a href="mailto:contact@whocalls.info?subject=Forgot password" style={{textAlign: 'right'}}>Forgot password</a>
               </Grid>
               <div style={{width: '100%'}} />
               <Grid item xs={10}>
                   {error && <Alert style={{margin: '0 20px'}} variant="filled" severity="error">
                    {error}
                </Alert>}
               </Grid>
                </Grid>
            </form>

        </div>
    );
};

export default Login;
