import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit';
import userReducer from '../components/auth/authSlice';
import receiversReducer from '../components/Receivers/receiversSlice';
import partnersReducer from '../components/Partners/partnersSlice';
import invoicesReducer from '../components/Invoices/invoicesSlice';
import dashboardReducer from '../components/Dashboard/dashboardSlice';
import notificationsReducer from '../components/Notifications/notificationsSlice';
import receiverHistoryReducer from '../components/ReceiverHistory/receiverHistorySlice';
import receiverActivityReducer from '../components/ReceiverActivity/receiverActivitySlice';
import receiverChangeListReducer from '../components/Receivers/receiver-change-list-slice';
import receiverActivityStatsReducer from '../components/Dashboard/receiver-activity-stats-slice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    receivers: receiversReducer,
    receiverChangeList: receiverChangeListReducer,
    receiverActivityStats: receiverActivityStatsReducer,
    partners: partnersReducer,
    invoices: invoicesReducer,
    dashboard: dashboardReducer,
    notifications: notificationsReducer,
    receiverHistory: receiverHistoryReducer,
    receiverActivity: receiverActivityReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
