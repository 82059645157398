import {createSlice} from '@reduxjs/toolkit';
import {AppThunk} from '../../store/store';
import {ReceiversActivityInterface} from '../../interfces/ReceiversInterface';
import {requestActivity} from '../../provider/receiversProvider';
import {getStorageItem} from '../../helpers/authHelper';
import {addErrorNotification} from '../Notifications/notificationsSlice';
import {timeStampToDate} from '../../helpers/format';
import {DEFAULT_PAGE_SIZE} from '../../helpers/constants';

const yesterdayDate = new Date();
yesterdayDate.setDate(yesterdayDate.getDate() - 1);
const initialState: ReceiversActivityInterface = {
    data: [],
    dateFrom: timeStampToDate(yesterdayDate.getTime()),
    dateTo: timeStampToDate(new Date().getTime()),
    pageSize: DEFAULT_PAGE_SIZE,
    pageCount: 0,
    total: 0,
    page: 0,
    query: '',
    sortBy: 'createdAt',
    sortDirection: 'desc',
    selectedPartnerId: null,
    loading: false,
};

export const receiverActivitySlice = createSlice({
    name: 'receiverActivity',
    initialState,
    reducers: {
        setActivity: (state, action) => {
            state.data = action.payload.data;
            state.pageCount = action.payload.pageCount;
            state.total = action.payload.total;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setSortBy: (state, action) => {
            state.sortBy = action.payload;
        },
        setDateFrom: (state, action) => {
            state.dateFrom = action.payload;
        },
        setDateTo: (state, action) => {
            state.dateTo = action.payload;
        },
        setSortDirection: (state, action) => {
            state.sortDirection = action.payload;
        },
        setRowsPerPage: (state, action) => {
            state.pageSize = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setQuery: (state, action) => {
            state.query = action.payload;
        },
        setSelectedPartnerId: (state, action) => {
            state.selectedPartnerId = action.payload;
        }
    },
});

export const {
    setActivity,
    setLoading,
    setSortBy,
    setSortDirection,
    setRowsPerPage,
    setSelectedPartnerId,
    setPage,
    setQuery,
    setDateFrom,
    setDateTo
} = receiverActivitySlice.actions;

export const getActivity = (partnerId: string = ''): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void,
    getState
) => {
    dispatch(setLoading(true));
    const {
        query,
        sortBy,
        sortDirection,
        page,
        pageSize,
        dateFrom,
        dateTo,
        selectedPartnerId
    } = getState().receiverActivity;
    const userPartnerId = getStorageItem('partnerId');
    const currentPartnerId = partnerId ? partnerId : selectedPartnerId !== null ? selectedPartnerId : userPartnerId;
    try {
        if (!userPartnerId) return;

        const response = await requestActivity({
            partnerId: currentPartnerId,
            dateFrom,
            dateTo,
            page,
            pageSize,
            sortBy,
            sortDirection,
            query
        });
        for (const item of response.data.data) {
            item.receiverId = `${item.receiverId}${item.serialNumber ? '/' + item.serialNumber : ''}`;
        }
        // @ts-ignore
        dispatch(setActivity(response.data));

    } catch (e) {
        dispatch(addErrorNotification(e));
    }
    dispatch(setLoading(false));
};

export const changeSorting = (property: string): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void,
    getState
) => {

    const {sortDirection} = getState().receiverActivity;
    if (sortDirection === 'asc') {
        dispatch(setSortDirection('desc'))
    } else {
        dispatch(setSortDirection('asc'));
    }
    dispatch(setSortBy(property));
    dispatch(setLoading(true));
    dispatch(getActivity());
}

export const handleQuery = (query: string): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void
) => {
    dispatch(setPage(0));
    dispatch(setLoading(true));
    dispatch(setQuery(query));
    dispatch(getActivity());
}
export const changeRowsPerPage = (rowsPerPage: number): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void
) => {
    dispatch(setLoading(true));
    dispatch(setRowsPerPage(rowsPerPage));
    dispatch(getActivity());
}

export const changePage = (pageNumber: number): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void
) => {
    dispatch(setLoading(true));
    dispatch(setPage(pageNumber));
    dispatch(getActivity());
}

export const changeDateFrom = (date: string) => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void
) => {
    dispatch(setDateFrom(date));
}
export const changeDateTo = (date: string) => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void
) => {
    dispatch(setDateTo(date));
}
export default receiverActivitySlice.reducer;
