import {Page} from './page';

export interface ColumnsInterface {
    sortable: boolean;
    name: string,
    label: string,
    width: number
}
export const TABLE_HEADERS: {[key:string]: ColumnsInterface[]} = {
    [Page.DASHBOARD_KPI]: [
        {name: 'receiverId', label: 'ReceiverId', width: 100, sortable: false},
        {name: 'type', label: 'Type', width: 100, sortable: false},
        {name: 'firstActivationDate', label: 'First Activation Date', width: 100, sortable: false},
        {name: 'endOfTrialDate', label: 'End of Trial Date', width: 100, sortable: false},
        {name: 'businessId', label: 'Business Id', width: 100, sortable: false},
        {name: 'businessName', label: 'Business Name', width: 100, sortable: false},
        {name: 'status', label: 'Status', width: 100, sortable: false},
        {name: 'ratio', label: 'Billing ratio (%)', width: 100, sortable: false},
    ],
    [Page.RECEIVERS_MANAGE]: [
        {name: 'receiverId', label: 'Receiver ID', sortable: true, width: 100},
        {name: 'serialNumber', label: 'Serial Number', sortable: true, width: 100},
        {name: 'createdAt', label: 'Created At', sortable: true, width: 100},
        {name: 'partnerId', label: 'PartnerID', sortable: true, width: 100},
        {name: 'type', label: 'Type', sortable: true, width: 50},
        {name: 'businessId', label: 'BusinessID', sortable: true, width: 100},
        {name: 'businessName', label: 'Business Name', sortable: true, width: 200},
        {name: 'isActive', label: 'Status', sortable: true, width: 50},
        {name: 'hasFeatureSMSAutoReply', label: 'SMS', sortable: true, width: 50},
        {name: 'comment', label: 'Comment', sortable: false, width: 120},
        {name: 'actions', label: '', sortable: false, width: 100},
    ],
    [Page.PARTNERS_MANAGE]: [
        {name: 'name', label: 'Name', sortable: true, width: 300},
        {name: 'since', label: 'Since', sortable: true, width: 200},
        {name: 'status', label: 'Status', sortable: true, width: 50},
        {name: 'hasFeatureSMSAutoReply', label: 'SMS', sortable: false, width: 50},
        {name: 'comment', label: 'Comment', sortable: false, width: 150},
        {name: 'actions', label: '', sortable: false, width: 50},
    ],
    [Page.RECEIVER_HISTORY]: [
        {name: 'createdAt', label: 'Created at', sortable: true, width: 100},
        {name: 'receiverId', label: 'Receiver ID', sortable: false, width: 100},
        {name: 'type', label: 'Type', sortable: false, width: 50},
        {name: 'businessId', label: 'BusinessID', sortable: false, width: 100},
        {name: 'businessName', label: 'Business Name', sortable: false, width: 100},
        {name: 'status', label: 'Status', sortable: false, width: 50},
        {name: 'partnerId', label: 'Partner ID', sortable: false, width: 100},
        {name: 'changedBy', label: 'Changed by', sortable: false, width: 50},
        {name: 'comment', label: 'Comment', sortable: false, width: 100},
    ],
    [Page.RECEIVER_ACTIVITY]: [
        {name: 'createdAt', label: 'Created at', sortable: true, width: 100},
        {name: 'receiverId', label: 'Receiver ID', sortable: false, width: 100},
        {name: 'callStatus', label: 'Call status', sortable: false, width: 200},
        {name: 'type', label: 'Type', sortable: false, width: 50},
        {name: 'callerId', label: 'Caller ID', sortable: false, width: 50},
        {name: 'businessId', label: 'BusinessID', sortable: false, width: 100},
        {name: 'businessName', label: 'Business Name', sortable: false, width: 100},
        {name: 'partnerId', label: 'Partner ID', sortable: false, width: 100},
    ],
};
