import {config} from 'dotenv';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { store } from './store/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider, createTheme} from "@material-ui/core/styles";
import Router from "./routes";

config();

const theme = createTheme({
    palette: {
        primary: {
            main: '#FF8753',
        },
        secondary: {
            main: '#30031F',
        },
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '1em'
            }
        }
    }
});
ReactDOM.render(
  <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
            <Router />
        </Provider>
      </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
