import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const formatDate = (dateString: string) => {
    return dayjs(dateString).tz(tz).format('YYYY-MM-DD HH:mm:ss');
}

export const formatDateForDisplay = (dateString: string) => {
    return dayjs(dateString).tz(tz).format('DD.MM.YYYY HH:mm:ss');
}

export const timeStampToDate = (timestamp: number) => {
    if (timestamp === 0) {
        return ''
    }

    return  dayjs(new Date(timestamp)).tz(tz).format('YYYY-MM-DD');
}

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
