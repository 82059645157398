import {ReceiverChangeList} from '../../interfces/api/receiver-change-list';
import Dialog from '@material-ui/core/Dialog';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    DialogActions,
    DialogTitle,
} from '@material-ui/core';
import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import {formatDateForDisplay} from '../../helpers/format';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

export interface ChangeListProps {
    data: ReceiverChangeList[];
    isPending: boolean;
    error?: string | null;
    isOpen: boolean;
    onClose: () => void;
    receiverId?: string | null;
}

const getChangeKeyName = (key: string) => {
    switch (key) {
        case 'businessId': {
            return 'Business ID';
        }
        case 'businessName': {
            return 'Business Name';
        }
        case 'comment': {
            return 'Comment';
        }
        case 'countryCode': {
            return 'Country Code';
        }
        case 'hasFeatureSMSAutoReply': {
            return 'SMS Auto Reply Active';
        }
        case 'internationalMessage': {
            return 'International Message';
        }
        case 'localMessage': {
            return 'Local Message';
        }
        case 'isActive': {
            return 'Is Active';
        }
        case 'maxSMSCount': {
            return 'Max SMS Count';
        }
        case 'partnerId': {
            return 'Partner ID';
        }
        case 'receiverId': {
            return 'Receiver ID';
        }
        case 'serialNumber': {
            return 'Serial Number';
        }
        case 'type': {
            return 'Type';
        }
        case 'receiverTrialDays': {
            return 'Trial Days';
        }
        default: {
            return key;
        }
    }
};

const getPrintableValue = (value: any): string => {
    if (typeof value === 'boolean') {
        return value ? 'Yes' : 'No';
    }
    if (value instanceof Date) {
        return formatDateForDisplay(new Date(value).toISOString());
    }
    return value;
};

const getTableCellStyle = (key: string, value: any) => {
    let style = {};
    const badStyle = {backgroundColor: '#ff6b6b'};
    switch (key) {
        case 'Is Battery Saver On': {
            if (value.trim() === 'true') {
                style = badStyle;
            }
            break;
        }
        case 'Is default app for calls': {
            if (value.trim() === 'false') {
                style = badStyle;
            }
            break;
        }
        case 'Is Notification allowed': {
            if (value.trim() === 'false') {
                style = badStyle;
            }
            break;
        }
        case 'Permission READ_CALL_LOG': {
            if (value.trim() === 'false') {
                style = badStyle;
            }
            break;
        }
        case 'Permission WRITE_CALL_LOG': {
            if (value.trim() === 'false') {
                style = badStyle;
            }
            break;
        }
        case 'Permission CAMERA': {
            if (value.trim() === 'false') {
                style = badStyle;
            }
            break;
        }
        case 'Permission READ_CONTACTS': {
            if (value.trim() === 'false') {
                style = badStyle;
            }
            break;
        }
        case 'Permission WRITE_CONTACTS': {
            if (value.trim() === 'false') {
                style = badStyle;
            }
            break;
        }
        case 'Permission RECORD_AUDIO': {
            if (value.trim() === 'false') {
                style = badStyle;
            }
            break;
        }
        case 'Permission SEND_SMS': {
            if (value.trim() === 'false') {
                style = badStyle;
            }
            break;
        }
        case 'Permission CALL_PHONE': {
            if (value.trim() === 'false') {
                style = badStyle;
            }
            break;
        }
        case 'Logged in': {
            if (value.trim() === 'false') {
                style = badStyle;
            }
            break;
        }
        case 'Auto start': {
            if (value.trim() === 'false') {
                style = badStyle;
            }
            break;
        }
        case 'Show on Lock screen': {
            if (value.trim() === 'false') {
                style = badStyle;
            }
            break;
        }
        case 'Display pop-up windows while running in the background': {
            if (value.trim() === 'false') {
                style = badStyle;
            }
            break;
        }
        case 'Display pop-up window': {
            if (value.trim() === 'false') {
                style = badStyle;
            }
            break;
        }
        case 'Permanent notification': {
            if (value.trim() === 'false') {
                style = badStyle;
            }
            break;
        }
        case 'Battery level': {
            const batteryLevel = parseInt(value.trim());
            if (!isNaN(batteryLevel) && batteryLevel < 20) {
                style = badStyle;
            }
            break;
        }
    }
    return style;
};

export const ChangeListComponent = (props: ChangeListProps) => {
    const {data, isOpen, onClose, receiverId, isPending, error} = props;

    return <Dialog open={isOpen}
                   onClose={onClose}
                   scroll={'paper'}
                   maxWidth={'xl'}>
        <DialogTitle id="form-dialog-title">Change log for {receiverId ?? ''}</DialogTitle>
        <DialogContent>
            {isPending && <Box>Loading...</Box>}
            {error && <Box>Error: {error}</Box>}
            <List>
                {data && data.map((el) => {
                    return <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{formatDateForDisplay(el.createdAt)} | {el.changedBy}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Field</TableCell>
                                            <TableCell>Old value</TableCell>
                                            <TableCell>New value</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {el.changeList && Object.entries(el.changeList).filter(chnage => {
                                            return chnage[0] !== 'updatedAt';
                                        }).map((change) => {
                                            const [key, value] = change;
                                            return <TableRow>
                                                <TableCell
                                                    style={getTableCellStyle(key, value.previousValue)}>{getChangeKeyName(key)}</TableCell>
                                                <TableCell
                                                    style={getTableCellStyle(key, value.previousValue)}>{getPrintableValue(value.previousValue)}</TableCell>
                                                <TableCell
                                                    style={getTableCellStyle(key, value.previousValue)}>{getPrintableValue(value.newValue)}</TableCell>
                                            </TableRow>;
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>;
                })}
            </List>
        </DialogContent>
        <DialogActions>
            {error && <span style={{color: 'red', fontWeight: 'bold'}}>All fields are required*</span>}
            <Button onClick={onClose} color="primary">
                Close
            </Button>
        </DialogActions>
    </Dialog>;
};