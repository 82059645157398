export const getYears = (stopYear: number): number[] => {
    const currentYear = new Date().getFullYear();
    let years: number[] = [];
    for(let i = currentYear; i >= stopYear; i--) {
        years.push(i);
    }
    return years;
}

export const getMonths = (): number[] => {
    return Array.from(Array(12).keys()).map((el) => el+1);
}