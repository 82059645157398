import {RootState} from "../store";

export const ReceiverActivitySelector = (state: RootState) => state.receiverActivity.data;
export const PageSelector = (state: RootState) => state.receiverActivity.page;
export const SortBySelector = (state: RootState) => state.receiverActivity.sortBy;
export const SortDirectionSelector = (state: RootState) => state.receiverActivity.sortDirection;
export const PageSizeSelector = (state: RootState) => state.receiverActivity.pageSize;
export const TotalSizeSelector = (status: RootState) => status.receiverActivity.total;
export const TotalPagesSelector = (state: RootState) => state.receiverActivity.pageCount;
export const DateFromSelector = (state: RootState) => state.receiverActivity.dateFrom;
export const DateToSelector = (state: RootState) => state.receiverActivity.dateTo;
export const LoadingSelector = (state: RootState) => state.receiverActivity.loading;
