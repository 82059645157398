import {createSlice} from '@reduxjs/toolkit';
import {AppThunk} from '../../store/store';
import {
    requestAddPartner, requestAppConfigUpdate,
    requestDeletePartner,
    requestPartners,
    requestUpdatePartner,
} from '../../provider/partnersProvider';
import {getStorageItem} from '../../helpers/authHelper';
import {PartnerInterface, PartnersInterface} from '../../interfces/PartnersInterface';
import {addErrorNotification, addNotification} from '../Notifications/notificationsSlice';
import {DEFAULT_PAGE_SIZE} from '../../helpers/constants';

const initialState: PartnersInterface = {
    data: [],
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0,
    query: '',
    sortBy: 'name',
    pageCount: 0,
    sortDirection: 'desc',
    loading: false,
    addingPartnerSuccess: false,
    editPartner: false,
};

export const partnersSlice = createSlice({
    name: 'partners',
    initialState,
    reducers: {
        setPartners: (state: PartnersInterface, action) => {
            state.data = action.payload.data.data;
            state.pageCount = action.payload.data.pageCount;
            state.total = action.payload.data.total;
        },
        setLoading: (state: PartnersInterface, action) => {
            state.loading = action.payload;
        },
        setSortBy: (state: PartnersInterface, action) => {
            state.sortBy = action.payload;
        },
        setSortDirection: (state: PartnersInterface, action) => {
            state.sortDirection = action.payload;
        },
        setRowsPerPage: (state: PartnersInterface, action) => {
            state.pageSize = action.payload;
        },
        setPage: (state: PartnersInterface, action) => {
            state.page = action.payload;
        },
        setQuery: (state: PartnersInterface, action) => {
            state.query = action.payload;
        },
        setAddingPartner: (state: PartnersInterface, action) => {
            state.addingPartnerSuccess = action.payload;
        },
        setEditPartner: (state: PartnersInterface, action) => {
            state.editPartner = action.payload;
        },
    },
});

export const {
    setPartners,
    setLoading,
    setSortBy,
    setSortDirection,
    setAddingPartner,
    setRowsPerPage,
    setPage,
    setQuery,
    setEditPartner,
} = partnersSlice.actions;

export const getPartners = (): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void,
    getState,
) => {
    dispatch(setLoading(true));
    const {query, sortBy, sortDirection, page, pageSize} = getState().partners;
    try {
        dispatch(setAddingPartner(false));
        const partnerId = getStorageItem('partnerId');
        if (!partnerId && partnerId !== 'admin') return false;

        const response = await requestPartners({page, pageSize, sortDirection, sortBy, query});
        const partnersData = {
            data: response.data,
            pageCount: response.data.pageCount,
            total: response.data.total
        };
        dispatch(setPartners(partnersData));
    } catch (e) {
        const errorText = Object.keys(e.response.data).length > 0 ? e.response.data.errors[0].message : 'something went wrong';
        dispatch(addNotification('error', errorText));
    }
    dispatch(setLoading(false));
};

export const appConfigUpdate = (partnerId: string): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void,
) => {
    dispatch(setLoading(true));
    try {
        const adminPartnerId = getStorageItem('partnerId');
        if (!adminPartnerId && adminPartnerId !== 'admin') return false;

        const response = await requestAppConfigUpdate(partnerId);
        if (response.status === 200) {
            dispatch(addNotification('success', 'App config update request sent successfully'));
        }
    } catch (e) {
        const errorText = Object.keys(e.response.data).length > 0 ? e.response.data.errors[0].message : 'something went wrong';
        dispatch(addNotification('error', errorText));
    } finally {
        dispatch(setLoading(false));
    }
};

export const changeSorting = (property: string): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void,
    getState,
) => {

    const {sortDirection} = getState().partners;
    if (sortDirection === 'asc') {
        dispatch(setSortDirection('desc'));
    } else {
        dispatch(setSortDirection('asc'));
    }
    dispatch(setLoading(true));
    dispatch(setSortBy(property));
    dispatch(getPartners());
};

export const handleQuery = (query: string): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void,
) => {
    dispatch(setPage(0));
    dispatch(setLoading(true));
    dispatch(setQuery(query));
    dispatch(getPartners());
};
export const changeRowsPerPage = (rowsPerPage: number): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void,
) => {
    dispatch(setLoading(true));
    dispatch(setRowsPerPage(rowsPerPage));
    dispatch(getPartners());
};

export const changePage = (pageNumber: number): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void,
) => {
    dispatch(setLoading(true));
    dispatch(setPage(pageNumber));
    dispatch(getPartners());
};

export const addPartner = ({formData}: { formData: PartnerInterface }): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void,
) => {
    dispatch(setLoading(true));
    try {
        await requestAddPartner(formData);
        dispatch(addNotification('success', 'partner added successfully'));
        dispatch(setAddingPartner(true));
        dispatch(setLoading(false));
        dispatch(getPartners());
    } catch (e) {
        dispatch(addErrorNotification(e));
        dispatch(setLoading(false));
    }

};

export const editPartner = ({formData}: { formData: PartnerInterface }): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void,
) => {
    dispatch(setLoading(true));
    dispatch(setEditPartner(false));
    try {
        await requestUpdatePartner(formData);
        setTimeout(() => {
            dispatch(addNotification('success', 'partner updated successfully'));
            dispatch(setEditPartner(true));
            dispatch(getPartners());
            dispatch(setLoading(false));
            dispatch(setEditPartner(false));
            dispatch(setLoading(false));
        }, 2000);

    } catch (e) {
        dispatch(addErrorNotification(e));
        dispatch(setLoading(false));
    }
};

export const deletePartner = (partnerId: string): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void,
    getState,
) => {
    dispatch(setLoading(true));
    try {
        await requestDeletePartner(partnerId);
        const partners = [...getState().partners.data];
        const pageCount = getState().partners.pageCount;
        const currentPartnerIndex = partners.findIndex((el) => el.partnerId === partnerId);
        partners.splice(currentPartnerIndex, 1);
        dispatch(setPartners({data: {data: partners}, pageCount}));

    } catch (e) {
        dispatch(addErrorNotification(e));
    }
    dispatch(setLoading(false));
};

export default partnersSlice.reducer;
