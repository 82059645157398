import {createSlice} from '@reduxjs/toolkit';
import {AppThunk} from '../../store/store';

interface NotificationInterface {
    type: string,
    message: string[]
}

const initialState: NotificationInterface = {
    type: '',
    message: [],
};

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setNotification: (state, action) => {
            state.type = action.payload.type;
            state.message = action.payload.message;
        },
        clearNotification: (state) => {
            state.type = '';
            state.message = [];
        }
    },
});

export const {setNotification, clearNotification} = notificationsSlice.actions;


export const addNotification = (type: string, message: string): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void,
) => {
    dispatch(setNotification({type, message: [message]}));
};

export const addErrorNotification = (e: any): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void,
) => {
    const messageObject = e.response && Object.keys(e.response.data).length > 0 ?
        e.response.data.errors.map((el: { message: string }) => el.message) :
        ['something went wrong'];
    dispatch(setNotification({type: 'error', message: messageObject}));
};

export const removeNotifications = (): AppThunk => async (
    dispatch: (arg0: AppThunk | { payload: any; type: string; }) => void,
) => {
    dispatch(clearNotification());
};

export default notificationsSlice.reducer;
