import http from './axios';
import {getStorageItem} from "../helpers/authHelper";
import {
    ReceiverInterface,
    RequestReceiverActivityInterface,
    RequestReceiverHistoryInterface,
    RequestReceiversInterface
} from "../interfces/ReceiversInterface";

export const requestReceivers = ({
                                     partnerId,
                                     isActive,
                                     page,
                                     pageSize,
                                     sortBy,
                                     sortDirection,
                                     query
                                 }: RequestReceiversInterface) => {
    const token = getStorageItem('token');
    partnerId = encodeURI(partnerId);

    const isActiveParam = typeof isActive === 'string' ? '' : `&isActive=${isActive}`;
    return http.get(`/private/partners/${partnerId}/receiver?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDirection=${sortDirection}${isActiveParam}&query=${query}`, {
            headers: {
                'Authorization': token
            }
        }
    );
}

export const requestUpsertReceiver = (formData: ReceiverInterface, previousPartnerId: string) => {
    const token = getStorageItem('token');
    const receiverId = encodeURI(formData.receiverId);

    return http.post(`/private/partners/${previousPartnerId}/receiver/${receiverId}`, {...formData}, {
            headers: {
                'Authorization': token
            }
        }
    );
}

export const requestDeleteReceiver = (receiverId: string, partnerId: string, type: string) => {
    const token = getStorageItem('token');
    partnerId = encodeURI(partnerId);
    receiverId = encodeURI(receiverId);

    return http.delete(`/private/partners/${partnerId}/receiver/${receiverId}?type=${type}`, {
            headers: {
                'Authorization': token
            }
        }
    );
}

export const requestHistory = ({
                                   partnerId,
                                   page,
                                   pageSize,
                                   sortBy,
                                   sortDirection,
                                   query,
                                   dateFrom,
                                   dateTo
                               }: RequestReceiverHistoryInterface) => {
    const token = getStorageItem('token');
    partnerId = encodeURI(partnerId);

    return http.get(
        `/private/partners/${partnerId}/receiver-history?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDirection=${sortDirection}&query=${query}&fromDate=${dateFrom}&toDate=${dateTo}`,
        {
            headers: {
                'Authorization': token
            }
        }
    );
}

export const requestActivity = ({
                                   partnerId,
                                   page,
                                   pageSize,
                                   sortBy,
                                   sortDirection,
                                   query,
                                   dateFrom,
                                   dateTo
                               }: RequestReceiverActivityInterface) => {
    const token = getStorageItem('token');
    partnerId = encodeURI(partnerId);

    return http.get(
        `/private/partners/${partnerId}/receiver-activity?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDirection=${sortDirection}&query=${query}&fromDate=${dateFrom}&toDate=${dateTo}`,
        {
            headers: {
                'Authorization': token
            }
        }
    );
}
export const getReceiverById = (receiverId: string, partnerId: string) => {
    const token = getStorageItem('token');
    partnerId = encodeURI(partnerId);
    receiverId = encodeURI(receiverId);

    return http.get(`/private/partners/${partnerId}/receiver/${receiverId}`, {
            headers: {
                'Authorization': token
            }
        }
    );
}
