import React from 'react';
import { Route, Redirect } from "react-router-dom";
import {getStorageItem} from "../helpers/authHelper";

const GuardedRoute = ({ component, exact, path, ...rest }: {component: React.FC, exact: boolean, path: string }) => {
    const token = getStorageItem('token');
    // @ts-ignore
    const {location} = rest;
    return (
        <Route {...rest} render={(props) => (
            token
                ? (<Route path={path} exact={exact} component={component} />)
                : <Redirect to={{pathname: '/login', state: { from:`${path}${location.search}`} }}/>
        )}/>
    );
}

export default GuardedRoute;
