import React, {useEffect, useState} from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {makeStyles} from '@material-ui/core/styles';
import {addPartner} from '../Partners/partnersSlice';
import {AddingPartnerSuccessSelector, LoadingSelector} from '../../store/selectors/partnersSelectors';
import {PartnerInterface} from '../../interfces/PartnersInterface';

const useStyles = makeStyles({
    newPosOfDialog: {
        position: 'absolute',
        width: '100%',
        minWidth: '780px',
        top: '50%',
        left: '50%',
        transform: 'translate(-55%, -50%)',
    },
});

export interface PartnerFormDialogProps {
    open: boolean;
    handleOpen: (value: boolean) => void;
}

const PartnerFormDialog = (props: PartnerFormDialogProps) => {
    const {open, handleOpen} = props;
    const dispatch = useAppDispatch();
    const addingPartnerSuccess = useAppSelector(AddingPartnerSuccessSelector);
    const loading = useAppSelector(LoadingSelector);
    const classes = useStyles();
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [registrationNumber, setRegistrationNumber] = useState('');
    const [vatCode, setVatCode] = useState('');
    const [contactPersonName, setContactPersonName] = useState('');
    const [contactPersonEmail, setContactPersonEmail] = useState('');
    const [contactPersonPhoneNumber, setContactPersonPhoneNumber] = useState('');
    const [iban, setIban] = useState('');
    const [status, setStatus] = useState('off');
    const [comment, setComment] = useState('');
    const [description, setDescription] = useState('');
    const [brandColor, setBrandColor] = useState('');
    const [logoUrl, setLogoUrl] = useState('');
    const [authenticationPropertyName, setAuthenticationPropertyName] = useState('');
    const [authenticationPropertyValue, setAuthenticationPropertyValue] = useState('');
    const [authenticationPropertyLocation, setAuthenticationPropertyLocation] = useState('');
    const [landingPageUrl, setLandingPageUrl] = useState('');
    const [resetPasswordUrl, setResetPasswordUrl] = useState('');
    const [userAuthorizationUrl, setUserAuthorizationUrl] = useState('');
    const [callerIdUrl, setCallerIdUrl] = useState('');
    const [receiverTrialDays, setReceiverTrialDays] = useState<number | null>(null);
    const [hasFeatureSMSAutoReply, setHasFeatureSMSAutoReply] = useState(false);
    const [dtmfUrl, setDtmfUrl] = useState<string | null>(null);
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');

    useEffect(() => {
        if (addingPartnerSuccess) {
            handleClose();
        }
        // eslint-disable-next-line
    }, [addingPartnerSuccess]);
    const clearForm = () => {
        setName('');
        setAddress('');
        setCity('');
        setCountry('');
        setState('');
        setZipCode('');
        setRegistrationNumber('');
        setVatCode('');
        setContactPersonName('');
        setContactPersonEmail('');
        setContactPersonPhoneNumber('');
        setIban('');
        setStatus('off');
        setComment('');
        setDescription('');
        setBrandColor('');
        setLogoUrl('');
        setAuthenticationPropertyLocation('query');
        setAuthenticationPropertyName('');
        setAuthenticationPropertyValue('');
        setLandingPageUrl('');
        setResetPasswordUrl('');
        setCallerIdUrl('');
        setUserAuthorizationUrl('');
        setReceiverTrialDays(null);
        setHasFeatureSMSAutoReply(false);
        setError(false);
        setErrorText('');
        setDtmfUrl(null);
    };
    const handleClose = () => {
        clearForm();
        handleOpen(false);
    };
    const handleConfirm = () => {
        const formData: PartnerInterface = {
            name,
            address,
            city,
            country,
            state,
            zipCode,
            registrationNumber,
            vatCode,
            contactPersonName,
            contactPersonEmail,
            contactPersonPhoneNumber,
            iban,
            status,
            comment,
            description,
            brandColor,
            logoUrl,
            authenticationPropertyLocation,
            authenticationPropertyName,
            authenticationPropertyValue,
            landingPageUrl,
            resetPasswordUrl,
            callerIdUrl,
            userAuthorizationUrl,
            partnerId: '',
            receiverTrialDays,
            hasFeatureSMSAutoReply,
            dtmfUrl
        };
        const validateData = {
            name,
            address,
            city,
            country,
            state,
            zipCode,
            registrationNumber,
            vatCode,
            contactPersonName,
            contactPersonEmail,
            contactPersonPhoneNumber,
            iban,
            status,
            description,
            brandColor,
            logoUrl,
            resetPasswordUrl,
            callerIdUrl,
            authenticationPropertyLocation,
            authenticationPropertyName,
            authenticationPropertyValue,
            landingPageUrl,
            userAuthorizationUrl,
            dtmfUrl
        };
        // @ts-ignore
        if (!Object.keys(validateData).every((el) => validateData[el].length > 0)) {
            setError(true);
            return false;
        }
        if (dtmfUrl && (dtmfUrl.length === 0 || !dtmfUrl.startsWith('https'))) {
            setError(true);
            setErrorText('DTMF URL must be a valid URL (https://)');
            return false;
        }
        setError(false);
        setErrorText('');
        dispatch(addPartner({
            formData,
        }));
    };
    const handleChangeSelect = (type: string, event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        switch (type) {
            case 'hasFeatureSMSAutoReply':
                setHasFeatureSMSAutoReply(event.target.value === 'on');
                break;
            case 'status':
                setStatus(event.target.value as string);
                break;
            case 'authenticationPropertyLocation':
                setAuthenticationPropertyLocation(event.target.value as string);
                break;
            default:
                break;
        }
    };
    const handleChange = (type: string, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setError(false);
        switch (type) {
            case 'receiverTrialDays':
                const value = event.target.value;
                if (value.length === 0) {
                    setReceiverTrialDays(null);
                    break;
                }
                const trialDays = parseInt(value);
                if (isNaN(trialDays)) {
                    setReceiverTrialDays(null);
                    break;
                }
                setReceiverTrialDays(Number(event.target.value));
                break;
            case 'name':
                setName(event.target.value);
                break;
            case 'contactPersonEmail':
                setContactPersonEmail(event.target.value);
                break;
            case 'address':
                setAddress(event.target.value);
                break;
            case 'city':
                setCity(event.target.value);
                break;
            case 'country':
                setCountry(event.target.value);
                break;
            case 'state':
                setState(event.target.value);
                break;
            case 'zipCode':
                setZipCode(event.target.value);
                break;
            case 'registrationNumber':
                setRegistrationNumber(event.target.value);
                break;
            case 'vatCode':
                setVatCode(event.target.value);
                break;
            case 'contactPersonName':
                setContactPersonName(event.target.value);
                break;
            case 'contactPersonPhoneNumber':
                setContactPersonPhoneNumber(event.target.value);
                break;
            case 'iban':
                setIban(event.target.value);
                break;
            case 'comment':
                setComment(event.target.value);
                break;
            case 'description':
                setDescription(event.target.value);
                break;
            case 'brandColor':
                setBrandColor(event.target.value);
                break;
            case 'logoUrl':
                setLogoUrl(event.target.value);
                break;
            case 'authenticationPropertyName':
                setAuthenticationPropertyName(event.target.value);
                break;
            case 'authenticationPropertyValue':
                setAuthenticationPropertyValue(event.target.value);
                break;
            case 'landingPageUrl':
                setLandingPageUrl(event.target.value);
                break;
            case 'resetPasswordUrl':
                setResetPasswordUrl(event.target.value);
                break;
            case 'callerIdUrl':
                setCallerIdUrl(event.target.value);
                break;
            case 'userAuthorizationUrl':
                setUserAuthorizationUrl(event.target.value);
                break;
            case 'dtmfUrl':
                setDtmfUrl(event.target.value);
                break;
            default:
                break;
        }
    };
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" classes={{
            paper: classes.newPosOfDialog,
        }}>
            <DialogTitle id="form-dialog-title">Add Partner</DialogTitle>
            <DialogContent>
                <Grid container spacing={4}>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth required error={error && name.length === 0}>
                            <InputLabel htmlFor="receiverId">Name</InputLabel>
                            <Input id="name" value={name}
                                   onChange={(event) => handleChange('name', event)}/>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth required error={error && address.length === 0}>
                            <InputLabel htmlFor="address">Address</InputLabel>
                            <Input id="address" value={address}
                                   onChange={(event) => handleChange('address', event)}/>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth required error={error && city.length === 0}>
                            <InputLabel htmlFor="city">City</InputLabel>
                            <Input id="city" value={city}
                                   onChange={(event) => handleChange('city', event)}/>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth required error={error && country.length === 0}>
                            <InputLabel htmlFor="country">Country</InputLabel>
                            <Input id="country" value={country}
                                   onChange={(event) => handleChange('country', event)}/>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth required error={error && state.length === 0}>
                            <InputLabel htmlFor="state">State</InputLabel>
                            <Input id="state" value={state}
                                   onChange={(event) => handleChange('state', event)}/>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth required error={error && zipCode.length === 0}>
                            <InputLabel htmlFor="zip">ZIP</InputLabel>
                            <Input id="zip" value={zipCode}
                                   onChange={(event) => handleChange('zipCode', event)}/>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth required error={error && registrationNumber.length === 0}>
                            <InputLabel htmlFor="regNumber">Registration Number</InputLabel>
                            <Input id="registrationNumber" value={registrationNumber}
                                   onChange={(event) => handleChange('registrationNumber', event)}/>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth required error={error && vatCode.length === 0}>
                            <InputLabel htmlFor="vat">VAT</InputLabel>
                            <Input id="vat" value={vatCode}
                                   onChange={(event) => handleChange('vatCode', event)}/>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth required error={error && contactPersonName.length === 0}>
                            <InputLabel htmlFor="contactPersonName">Contact Person</InputLabel>
                            <Input id="contactPersonName" value={contactPersonName}
                                   onChange={(event) => handleChange('contactPersonName', event)}/>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth required error={error && contactPersonEmail.length === 0}>
                            <InputLabel htmlFor="contactPersonEmail">Email</InputLabel>
                            <Input id="contactPersonEmail" value={contactPersonEmail} type="email"
                                   onChange={(event) => handleChange('contactPersonEmail', event)}/>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth required error={error && contactPersonPhoneNumber.length === 0}>
                            <InputLabel htmlFor="contactPersonPhoneNumber">Phone</InputLabel>
                            <Input id="contactPersonPhoneNumber" value={contactPersonPhoneNumber}
                                   onChange={(event) => handleChange('contactPersonPhoneNumber', event)}/>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth required error={error && iban.length === 0}>
                            <InputLabel htmlFor="iban">IBAN</InputLabel>
                            <Input id="iban" value={iban}
                                   onChange={(event) => handleChange('iban', event)}/>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth required error={error && description.length === 0}>
                            <InputLabel htmlFor="description">Description</InputLabel>
                            <Input id="description" value={description}
                                   onChange={(event) => handleChange('description', event)}/>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth required error={error && brandColor.length === 0}>
                            <InputLabel htmlFor="brandColor">Brand Color</InputLabel>
                            <Input id="brandColor" value={brandColor}
                                   onChange={(event) => handleChange('brandColor', event)}/>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth required error={error && logoUrl.length === 0}>
                            <InputLabel htmlFor="logoUrl">Logo Url</InputLabel>
                            <Input id="logoUrl" value={logoUrl}
                                   onChange={(event) => handleChange('logoUrl', event)}/>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="status">Authentication property
                                location</InputLabel>
                            <Select
                                labelId="authenticationPropertyLocation"
                                id="authenticationPropertyLocation"
                                value={authenticationPropertyLocation}
                                onChange={(event) => handleChangeSelect('authenticationPropertyLocation', event)}
                            >
                                <MenuItem value={'query'}>query</MenuItem>
                                <MenuItem value={'header'}>header</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth required error={error && authenticationPropertyName.length === 0}>
                            <InputLabel htmlFor="authenticationPropertyName">Authentication property name</InputLabel>
                            <Input id="authenticationPropertyName" value={authenticationPropertyName}
                                   onChange={(event) => handleChange('authenticationPropertyName', event)}/>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth required error={error && authenticationPropertyValue.length === 0}>
                            <InputLabel htmlFor="authenticationPropertyValue">Authentication property value</InputLabel>
                            <Input id="authenticationPropertyValue" value={authenticationPropertyValue}
                                   onChange={(event) => handleChange('authenticationPropertyValue', event)}/>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth required error={error && landingPageUrl.length === 0}>
                            <InputLabel htmlFor="landingPageUrl">Landing page url</InputLabel>
                            <Input id="landingPageUrl" value={landingPageUrl}
                                   onChange={(event) => handleChange('landingPageUrl', event)}/>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth required error={error && resetPasswordUrl.length === 0}>
                            <InputLabel htmlFor="resetPasswordUrl">Reset password url</InputLabel>
                            <Input id="resetPasswordUrl" value={resetPasswordUrl}
                                   onChange={(event) => handleChange('resetPasswordUrl', event)}/>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth required error={error && callerIdUrl.length === 0}>
                            <InputLabel htmlFor="callerIdUrl">Caller id url</InputLabel>
                            <Input id="callerIdUrl" value={callerIdUrl}
                                   onChange={(event) => handleChange('callerIdUrl', event)}/>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth required error={error && userAuthorizationUrl.length === 0}>
                            <InputLabel htmlFor="userAuthorizationUrl">Authorization Url</InputLabel>
                            <Input id="userAuthorizationUrl" value={userAuthorizationUrl}
                                   onChange={(event) => handleChange('userAuthorizationUrl', event)}/>
                        </FormControl>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <FormControl fullWidth error={error && dtmfUrl !== null && dtmfUrl.length === 0}>
                            <InputLabel htmlFor="dtmfUrl">{error? `DTMF Key Url (${errorText})`: 'DTMF Key Url'}</InputLabel>
                            <Input id="dtmfUrl" value={dtmfUrl}
                                   onChange={(event) => handleChange('dtmfUrl', event)}/>
                        </FormControl>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                id="comment"
                                label="Comment"
                                multiline
                                rows={4}
                                value={comment}
                                onChange={(event) => handleChange('comment', event)}
                                variant="outlined"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="status">Status</InputLabel>
                            <Select
                                labelId="status"
                                id="status"
                                value={status}
                                onChange={(event) => handleChangeSelect('status', event)}
                            >
                                <MenuItem value={'off'}>Off</MenuItem>
                                <MenuItem value={'on'}>On</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth required>
                            <InputLabel id="hasFeatureSMSAutoReply">SMS Auto Reply</InputLabel>
                            <Select
                                onChange={(event) => handleChangeSelect('hasFeatureSMSAutoReply', event)}
                                labelId="hasFeatureSMSAutoReply"
                                id="hasFeatureSMSAutoReply"
                                value={hasFeatureSMSAutoReply ? 'on' : 'off'}
                            >
                                <MenuItem key={'on'} value={'on'}>{'On'}</MenuItem>
                                <MenuItem key={'off'} value={'off'}>{'Off'}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <FormControl fullWidth error={error && userAuthorizationUrl.length === 0}>
                            <InputLabel htmlFor="receiverTrialDays">Receiver Trial Days</InputLabel>
                            <Input id="receiverTrialDays" value={receiverTrialDays} type={'number'}
                                   onChange={(event) => handleChange('receiverTrialDays', event)}/>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {error && <span style={{color: 'red', fontWeight: 'bold'}}>All fields are required*</span>}
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleConfirm} color="primary">
                    {loading ? <CircularProgress size={25} style={{color: '#fff'}}/> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PartnerFormDialog;
