import React from 'react';
import {Button, Grid, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import SearchInput from './Search';

import {makeStyles} from '@material-ui/core/styles';
import PartnersSelect from './PartnerSelect';
import PartnerFormDialog from '../forms/PartnerFormDialog';
import {getStorageItem} from '../../helpers/authHelper';
import ReceiverHistoryFilter from './ReceiverHistoryFilter';
import ReceiverActivityFilter from './ReceiverActivityFilter';
import HomeIcon from '@material-ui/icons/Home';
import EditReceiverFormDialog from '../forms/EditReceiverFormDialog';
import {Page, PageManager} from '../../constants/page';
import {ReceiversFilter} from './receivers-filter';

const useStyles = makeStyles({
    wrapper: {
        padding: '10px 0',
    },
    addButton: {
        background: '#fff',
        color: 'grey',
        marginRight: 15,
    },
    userName: {
        textTransform: 'uppercase', fontWeight: 'bold',
    },
    withHomeButton: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
});


const AppToolbar = ({page}: { page: Page }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const partnerId = getStorageItem('partnerId');
    const handleOpenDialog = (value: boolean) => {
        setOpen(value);
    };

    if (!PageManager.mainPages.includes(page)) {
        return <div className={classes.withHomeButton}>
            <Typography variant="h4" className={classes.userName} color="primary">{partnerId}</Typography>
            <Link to={Page.RECEIVERS_MANAGE} replace>
                <HomeIcon color="primary"/>
            </Link>
        </div>;
    }
    return (
        <Grid container alignItems="center" className={classes.wrapper}>
            {[Page.DASHBOARD_KPI].includes(page) &&
                <Grid container item xs={8} md={8} lg={6} justifyContent="space-between"
                      style={{maxWidth: '700px', marginRight: 10}}>
                    <PartnersSelect/>
                </Grid>
            }
            {page === Page.RECEIVER_HISTORY &&
                <Grid container item xs={8} md={8} lg={4} justifyContent="space-between"
                      style={{maxWidth: '700px', marginRight: 10}}>
                    <ReceiverHistoryFilter/>
                </Grid>}
            {page === Page.RECEIVER_ACTIVITY &&
                <Grid container item xs={8} md={8} lg={4} justifyContent="space-between"
                      style={{maxWidth: '700px', marginRight: 10}}>
                    <ReceiverActivityFilter/>
                </Grid>}
            {page === Page.RECEIVERS_MANAGE && <>
                <Grid item xs={3}>
                    <ReceiversFilter/>
                </Grid>
                <Button
                    onClick={() => handleOpenDialog(true)}
                    className={classes.addButton}
                    variant="outlined"
                >
                    Add Receiver
                </Button>
            </>}
            {page === Page.PARTNERS_MANAGE &&
                <Button
                    onClick={() => handleOpenDialog(true)}
                    className={classes.addButton}
                    variant="outlined"
                >
                    Add Partner
                </Button>}
            <Grid container item xs={4} md={4} lg={4} justifyContent="flex-start">
                {page !== Page.DASHBOARD_KPI && <SearchInput type={page}/>}

                {page !== Page.RECEIVER_HISTORY && page === Page.RECEIVERS_MANAGE ?
                    <EditReceiverFormDialog open={open} handleOpen={handleOpenDialog}/> :
                    <PartnerFormDialog open={open} handleOpen={handleOpenDialog}/>}
            </Grid>
        </Grid>
    );
};

export default AppToolbar;
