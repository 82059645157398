import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {appConfigUpdate, changePage, changeRowsPerPage, changeSorting, deletePartner} from './partnersSlice';
import {
    LoadingSelector,
    PageSelector,
    PageSizeSelector,
    PartnersSelector,
    SortBySelector,
    SortDirectionSelector,
    TotalSizeSelector,
} from '../../store/selectors/partnersSelectors';
import {LinearProgress} from '@material-ui/core';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import PartnerFormDialog from '../forms/PartnerFormDialog';
import EditPartnerFormDialog from '../forms/EditPartnerFormDialog';
import {getStorageItem} from '../../helpers/authHelper';
import {useHistory} from 'react-router-dom';
import {Role} from '../../constants/roles';
import {Page} from '../../constants/page';
import PartnersTable from '../Table/partners-table';

const Partners = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [partnerId, setPartnerId] = useState('');
    const [currentPartner, setCurrentPartner] = useState(null);
    const partners = useAppSelector(PartnersSelector);
    const page = useAppSelector(PageSelector);
    const pageSize = useAppSelector(PageSizeSelector);
    const sortBy = useAppSelector(SortBySelector);
    const sortDirection = useAppSelector(SortDirectionSelector);
    const loading = useAppSelector(LoadingSelector);
    const totalItems = useAppSelector(TotalSizeSelector);

    useEffect(() => {
        const userPartnerId = getStorageItem('partnerId');
        if (userPartnerId !== Role.ADMIN.toString()) {
            history.push('/receivers');
        }
    }, [history]);

    const handleSort = (property: string) => {
        dispatch(changeSorting(property));
    };
    const handleSetRowsPerPage = (rowsPerPage: number) => {
        dispatch(changeRowsPerPage(rowsPerPage));
    };
    const handleSetPage = (pageNumber: number) => {
        dispatch(changePage(pageNumber));
    };
    const cancelEdit = () => {
        setCurrentPartner(null);
    };
    const handleEdit = ({itemId}: { itemId: string, type: string }) => {
        const currentPartner = partners.find(el => el.partnerId === itemId);
        if (currentPartner !== null) {
            // @ts-ignore
            setCurrentPartner(currentPartner);
        }
    };
    const handleDelete = ({itemId}: { itemId: string, type: string }) => {
        setOpenDelete(true);
        setPartnerId(itemId);
    };
    const confirmDelete = () => {
        dispatch(deletePartner(partnerId));
        setOpenDelete(false);
    };
    const closeConfirm = () => {
        setOpenDelete(false);
    };
    const requestConfigUpdate = ({itemId}: { itemId: string, type: string }) => {
        console.log('requestConfigUpdate', itemId);
        dispatch(appConfigUpdate(itemId));
    };
    return (
        <div>
            {loading && <LinearProgress color="primary"/>}
            <PartnersTable pageName={Page.PARTNERS_MANAGE}
                           loading={loading}
                           data={partners}
                           page={page}
                           pageSize={pageSize}
                           sortBy={sortBy}
                           setRowsPerPage={handleSetRowsPerPage}
                           sortDirection={sortDirection}
                           onRequestSort={handleSort}
                           totalCount={totalItems}
                           setPage={handleSetPage}
                           editItem={handleEdit}
                           deleteItem={handleDelete}
                           requestConfigUpdate={requestConfigUpdate}
            />
            <ConfirmDialog handleConfirm={confirmDelete} handleClose={closeConfirm} open={openDelete}/>
            <PartnerFormDialog open={open} handleOpen={() => setOpen(false)}/>
            {currentPartner && <EditPartnerFormDialog partner={currentPartner} handleOpen={cancelEdit}/>}
        </div>
    );
};
export default Partners;
