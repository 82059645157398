import {RootState} from "../store";

export const PartnersSelector = (state: RootState) => state.partners.data;
export const LoadingSelector = (state: RootState) => state.partners.loading;
export const PageSelector = (state: RootState) => state.partners.page;
export const SortBySelector = (state: RootState) => state.partners.sortBy;
export const SortDirectionSelector = (state: RootState) => state.partners.sortDirection;
export const PageSizeSelector = (state: RootState) => state.partners.pageSize;
export const TotalSizeSelector = (status: RootState) => status.partners.total;
export const TotalPagesSelector = (state: RootState) => state.partners.pageCount;
export const AddingPartnerSuccessSelector = (state: RootState) => state.partners.addingPartnerSuccess;
export const EditPartnerSelector = (state: RootState) => state.partners.editPartner;
