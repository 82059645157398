import React from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {
    ActivityStatsIsPendingSelector,
    ActivityStatsPageSelector,
    ActivityStatsSelector,
    ActivityStatsSizeSelector,
    setPage,
    setSize,
} from './receiver-activity-stats-slice';
import MainTable from '../Table/Table';
import {Page} from '../../constants/page';


const Dashboard = () => {
    const dashboardData = useAppSelector(ActivityStatsSelector);
    const loading = useAppSelector(ActivityStatsIsPendingSelector);
    const dispatch = useAppDispatch();
    const page = useAppSelector(ActivityStatsPageSelector);
    const size = useAppSelector(ActivityStatsSizeSelector);

    if (loading) {
        return <div>Loading...</div>;
    }

    const noop = () => {
    };

    const setPageNumber = (page: number) => {
        dispatch(setPage(page));
    };

    const setPageSize = (size: number) => {
        dispatch(setSize(size));
    };

    return <>
        <MainTable pageName={Page.DASHBOARD_KPI}
                   loading={loading}
                   data={dashboardData}
                   page={page}
                   pageSize={size}
                   totalCount={dashboardData.length}
                   onRequestSort={noop}
                   setPage={setPageNumber}
                   setRowsPerPage={setPageSize}
                   sortDirection={'desc'}/>;
    </>;
};

export default Dashboard;
