import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {PartnersSelector} from '../../store/selectors/partnersSelectors';
import {Button, FormControl, Grid, InputLabel, MenuItem, Select} from '@material-ui/core';
import {PartnerInterface} from '../../interfces/PartnersInterface';
import {getStorageItem} from '../../helpers/authHelper';
import {getMonths, getYears} from '../../helpers/main';
import {
    actionGetReceiverActivityStats,
    ActivityStatsMonthSelector,
    ActivityStatsPageSelector,
    ActivityStatsPartnerIdSelector,
    ActivityStatsSelector,
    ActivityStatsSizeSelector,
    ActivityStatsYearSelector,
    setMonth,
    setPartnerId,
    setYear,
} from '../Dashboard/receiver-activity-stats-slice';
import {ReceiverActivityStats} from '../../interfces/api/receiver-activity-stats';

const getCSVValues = (activityStats: ReceiverActivityStats[]) => {
    return activityStats
        .map((el) => el.receiverId + ',' + el.type + ',' + el.firstActivationDate + ','
            + el.endOfTrialDate + ',' + el.businessId + ',' + el.businessName + ',' + el.status + ',' + el.ratio)
        .join('\r\n');
};

interface OnChangeProps {
    name?: string | undefined,
    value: unknown
}

const PartnersSelect = () => {

    const partnerIdFromToken = getStorageItem('partnerId');

    const preparedYears = getYears(2021);
    const months = getMonths();
    const partnerId = useAppSelector(ActivityStatsPartnerIdSelector);
    const month = useAppSelector(ActivityStatsMonthSelector);
    const year = useAppSelector(ActivityStatsYearSelector);
    const dispatch = useAppDispatch();
    const partners = useAppSelector(PartnersSelector);
    const activityStats = useAppSelector(ActivityStatsSelector);
    const page = useAppSelector(ActivityStatsPageSelector);
    const size = useAppSelector(ActivityStatsSizeSelector);

    useEffect(() => {
        dispatch(actionGetReceiverActivityStats({
            partnerId: partnerId,
            month: month,
            year: year,
            page: page,
            size: size,
        }));
    }, [month, year, partnerIdFromToken, partnerId, dispatch, page, size]);

    const handleSetPartner = (event: React.ChangeEvent<OnChangeProps>) => {
        dispatch(setPartnerId(event.target.value as string));
    };
    const handleSetMonth = (event: React.ChangeEvent<OnChangeProps>) => {
        dispatch(setMonth(event.target.value as number));
    };
    const handleSetYear = (event: React.ChangeEvent<OnChangeProps>) => {
        dispatch(setYear(event.target.value as number));
    };

    const exportCSV = () => {
        const header = 'Receiver ID,Type,First Activation Date,End Of Trial Date,Business ID,Business Name,Status,Billing Ratio (%)';
        const values = getCSVValues(activityStats);
        const element = document.createElement('a');
        const file = new Blob([`${header}\r\n${values}`], {
            type: 'text/csv;charset=utf-8',
        });
        element.href = URL.createObjectURL(file);
        element.download = `${year}-${month}-${partnerId}.csv`;
        document.body.appendChild(element);
        element.click();
    };

    return (
        <Grid container spacing={4} style={{width: 500}} alignItems="center">
            <Grid item xs={3}>
                {partnerIdFromToken === 'admin' ?
                    <FormControl variant="filled" style={{width: 100, background: 'fff', margin: 5}}>
                        <InputLabel id="selectPartner">Partner</InputLabel>
                        <Select
                            onChange={handleSetPartner}
                            labelId="selectPartner"
                            id="selectPartner"
                            value={partnerId}
                        >
                            <MenuItem value={'*'}>*</MenuItem>
                            {partners.map((partner: PartnerInterface, index) => (
                                <MenuItem key={`${index}-partner`}
                                          value={partner.partnerId}>{partner.name}</MenuItem>))}
                        </Select>
                    </FormControl> : <></>}
            </Grid>
            <Grid item xs={3}>
                <FormControl variant="filled" style={{width: 100, background: 'fff', margin: 5}}>
                    <InputLabel id="selectYear">Year</InputLabel>
                    <Select
                        onChange={handleSetYear}
                        labelId="selectYear"
                        id="selectYear"
                        value={year}
                    >
                        {preparedYears.map((item, index) => (
                            <MenuItem key={`${index}-year`} value={item}>{item}</MenuItem>))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl variant="filled" style={{width: 100, background: 'fff', margin: 5}}>
                    <InputLabel id="selectMonth">Month</InputLabel>
                    <Select
                        onChange={handleSetMonth}
                        labelId="selectMonth"
                        id="selectMonth"
                        value={month}
                    >
                        {months.map((month) => <MenuItem key={`${month}-month`} value={month}>{month}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl variant="filled" style={{width: 100, margin: 5}}>
                    <Button variant={'contained'}
                            style={{backgroundColor: '#FF8753'}}
                            onClick={exportCSV}
                            disabled={!activityStats || activityStats.length === 0}>
                        Export CSV
                    </Button>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default PartnersSelect;
