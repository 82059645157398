import React from 'react';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import _ from 'underscore';
import InputBase from '@material-ui/core/InputBase';
import {useAppDispatch} from "../../hooks";
import {handleQuery as receiverQuery} from "../Receivers/receiversSlice";
import {handleQuery as partnerQuery} from "../Partners/partnersSlice";
import {handleQuery as receiverHistoryQuery} from "../ReceiverHistory/receiverHistorySlice";
import {handleQuery as receiverActivityQuery} from "../ReceiverActivity/receiverActivitySlice";
import {Page} from '../../constants/page';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
            width: 300,
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            height: 28,
            margin: 4,
        },
    }),
);

export default function SearchInput({type}: { type: string }) {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const searchPlaceholder = () => {
        if (type === Page.RECEIVERS_MANAGE || type === Page.RECEIVER_HISTORY || type === Page.RECEIVER_ACTIVITY) {
            return 'Type to search'
        }
        if (type === Page.PARTNERS_MANAGE) {
            return 'Type Partner ID or comment'
        }
        return ''
    }
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
        if (type === Page.RECEIVERS_MANAGE) {
            dispatch(receiverQuery(query));
        }
        if (type === Page.PARTNERS_MANAGE) {
            dispatch(partnerQuery(query));
        }
        if (type === Page.RECEIVER_HISTORY) {
            dispatch(receiverHistoryQuery(query))
        }
        if (type === Page.RECEIVER_ACTIVITY) {
            dispatch(receiverActivityQuery(query))
        }
    }
    return (
        <Paper component="form" className={classes.root} onSubmit={(event) => event.preventDefault()}>
            <InputBase
                fullWidth
                onChange={_.debounce(handleSearch, 500)}
                className={classes.input}
                placeholder={searchPlaceholder()}
                inputProps={{'aria-label': 'search'}}
            />
        </Paper>
    );
}
