import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {changePage, changeRowsPerPage, changeSorting, getActivity} from './receiverActivitySlice';
import MainTable from '../Table/Table';
import {LinearProgress} from '@material-ui/core';
import {
    LoadingSelector,
    PageSelector,
    PageSizeSelector,
    ReceiverActivitySelector,
    SortBySelector,
    SortDirectionSelector,
    TotalSizeSelector,
} from '../../store/selectors/receiversActivitySelectors';
import {Page} from '../../constants/page';

const ReceiverActivity = () => {
    const dispatch = useAppDispatch();
    const activity = useAppSelector(ReceiverActivitySelector);
    const page = useAppSelector(PageSelector);
    const pageSize = useAppSelector(PageSizeSelector);
    const sortBy = useAppSelector(SortBySelector);
    const sortDirection = useAppSelector(SortDirectionSelector);
    const loading = useAppSelector(LoadingSelector);
    const totalItems = useAppSelector(TotalSizeSelector);

    useEffect(() => {
        dispatch(getActivity())
    }, [dispatch])
    const handleSort = (property: string) => {
        dispatch(changeSorting(property));
    }
    const handleSetRowsPerPage = (rowsPerPage: number) => {
        dispatch(changeRowsPerPage(rowsPerPage))
    }
    const handleSetPage = (pageNumber: number) => {
        dispatch(changePage(pageNumber))
    }
    return (
        <div>
            {loading && <LinearProgress color="primary"/>}
            <MainTable pageName={Page.RECEIVER_ACTIVITY}
                       loading={loading}
                       data={activity}
                       page={page}
                       pageSize={pageSize}
                       sortBy={sortBy}
                       setRowsPerPage={handleSetRowsPerPage}
                       sortDirection={sortDirection}
                       onRequestSort={handleSort}
                       totalCount={totalItems}
                       setPage={handleSetPage}
            />
        </div>
    );
};
export default ReceiverActivity;
